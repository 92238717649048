import * as j from '@mojotech/json-type-validation';

import {
  DiallerGroupPreviewSettings,
  DiallingHours,
  Disposition,
  LeadStatusType,
  OutcomeType,
  PublicHoliday,
} from '~pages/CampaignManagement/domain';
import { DiallableLead } from '~pages/Dialler/domain';

export enum AttemptCreationContext {
  Standard = 'standard',
  ManualPrepared = 'manual-prepared',
  ReceivedTransfer = 'received-transfer',
  Callback = 'callback',
  Inbound = 'inbound',
  UnknownInbound = 'unknown-inbound',
  ManagedInboundQueue = 'managed-inbound-queue',
}

export interface AttemptDetails {
  attemptId: number;
  creationContext: AttemptCreationContext | undefined;
  assignedTimestamp: string | undefined;
  initiatedTimestamp: string | undefined;
  connectedTimestamp: string | undefined;
  disconnectedTimestamp: string | undefined;
  contactId: string | undefined;
  diallingHours: DiallingHours | undefined;
  lead: DiallableLead | null;
  previewSettings: DiallerGroupPreviewSettings | undefined;
  dispositions: Disposition[] | undefined;
  publicHolidays: PublicHoliday[];
  exclusionLists: string[];
}

export interface Attempt extends Omit<AttemptDetails, 'previewSettings'> {
  // Should be identical reference to initiated boolean,
  // only time it would be different is for a new lead in the assigned state
  // that has been dialled by the UI where this value is marked as true and initiated is false until
  // we post its status update.
  /** This property will always be false if attempt is the result of a return call */
  dialled: boolean;
  initiated: boolean;
  connected: boolean;
  disconnected: boolean;
  /**
   * This is used primarily to flag an inbound call as a return call attempt.
   */
  isInboundReturnCall: boolean;
  /**
   * This is used primarily to flag an inbound call is an unknown inbound call attempt.
   */
  isUnknownInbound: boolean;
  isInternalTransfer: boolean;
  autoDialEnabled: boolean;
  autoHangUpEnabled: boolean;
  autoDialTimer: number | undefined;
  autoDialRunning: boolean;
  autoHangUpTimer: number | undefined;
  autoHangUpRunning: boolean;
}

export const DiallingHoursResponseDecoder: j.Decoder<DiallingHours> = j
  .object({
    allow_public_holidays: j.boolean(),
    dialling_days: j.dict(
      j.union(
        j.object({
          start_time_hour: j.number(),
          start_time_min: j.number(),
          end_time_hour: j.number(),
          end_time_min: j.number(),
        }),
        j.constant(null),
      ),
    ),
  })
  .map((item) => ({
    allowPublicHolidays: item.allow_public_holidays,
    diallingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reduce((out, day) => {
      const hrs = item.dialling_days[day];
      return {
        ...out,
        [day]: hrs
          ? {
              startTimeHour: hrs.start_time_hour,
              startTimeMin: hrs.start_time_min,
              endTimeHour: hrs.end_time_hour,
              endTimeMin: hrs.end_time_min,
            }
          : null,
      };
    }, {}),
  }));

export const DispositionItemResponseDecoder: j.Decoder<Disposition> = j
  .object({
    title: j.string(),
    description: j.string(),
    code: j.string(),
    sub_code: j.string(),
    outcome: j.oneOf(
      j.constant(OutcomeType.Contacted),
      j.constant(OutcomeType.Callback),
      j.constant(OutcomeType.NoAnswer),
      j.constant(OutcomeType.AnsweringMachine),
      j.constant(OutcomeType.Engaged),
      j.constant(OutcomeType.InvalidEndpoint),
      j.constant(OutcomeType.HoldDropped),
      j.constant(OutcomeType.Excluded),
      j.constant(OutcomeType.Skipped),
      j.constant(OutcomeType.Removed),
      j.constant(OutcomeType.RequiredAgentUnavailable),
      j.constant(OutcomeType.AssignmentExpired),
      j.constant(OutcomeType.Error),
      j.constant(OutcomeType.Fatal),
      j.constant(OutcomeType.AwaitingCallback),
    ),
    attributes: j.array(
      j.object({
        attribute: j.string(),
        value: j.string(),
      }),
    ),
  })
  .map((item) => ({
    title: item.title,
    description: item.description,
    outcome: item.outcome,
    code: item.code,
    subCode: item.sub_code,
    attributes: item.attributes.map((attr: any) => ({
      attribute: attr.attribute,
      value: attr.value,
    })),
  }));

export const PublicHolidayItemResponseDecoder: j.Decoder<PublicHoliday> = j
  .object({
    name: j.string(),
    day: j.number(),
    month: j.number(),
    year: j.number(),
  })
  .map((item) => ({
    name: item.name,
    day: item.day,
    month: item.month,
    year: item.year,
  }));

export const AttemptLeadResponseDecoder: j.Decoder<DiallableLead> = j
  .object({
    lead_id: j.number(),
    external_id: j.string(),
    lead_name: j.string(),
    lead_status: j.oneOf(
      j.constant(LeadStatusType.Assigned),
      j.constant(LeadStatusType.AwaitingRetry),
      j.constant(LeadStatusType.AwaitingStart),
      j.constant(LeadStatusType.Building),
      j.constant(LeadStatusType.Callback),
      j.constant(LeadStatusType.Connected),
      j.constant(LeadStatusType.Contacted),
      j.constant(LeadStatusType.Duplicate),
      j.constant(LeadStatusType.Excluded),
      j.constant(LeadStatusType.Filtered),
      j.constant(LeadStatusType.Finished),
      j.constant(LeadStatusType.FinishedToday),
      j.constant(LeadStatusType.Initiated),
      j.constant(LeadStatusType.NoEndpoints),
      j.constant(LeadStatusType.NoSkilledAgents),
      j.constant(LeadStatusType.OptOut),
      j.constant(LeadStatusType.OutOfHours),
      j.constant(LeadStatusType.Ready),
      j.constant(LeadStatusType.Washed),
      j.constant(LeadStatusType.Disconnected),
      j.constant(LeadStatusType.InvalidEndpoint),
      j.constant(LeadStatusType.Removed),
      j.constant(LeadStatusType.Expired),
      j.constant(LeadStatusType.AwaitingSMS),
      j.constant(LeadStatusType.InactiveList),
      j.constant(LeadStatusType.MissedCallback),
      j.constant(LeadStatusType.InQueue),
      j.constant(LeadStatusType.AwaitingCallback),
      j.constant(LeadStatusType.Replaced),
    ),
    timezone: j.string(),
    campaign_id: j.number(),
    endpoints: j.array(j.string()),
    required_skills: j.string(),
    callback_id: j.union(j.number(), j.constant(null)),
    callback_notes: j.union(j.string(), j.constant(null)),
    attributes: j.dict(j.string()),
    endpoint_attributes: j.dict(
      j.object({
        type: j.string(),
        attributes: j.dict(j.string()),
      }),
    ),
  })
  .map((item) => ({
    id: item.lead_id,
    externalId: item.external_id,
    name: item.lead_name,
    status: item.lead_status,
    timezone: item.timezone,
    campaignId: item.campaign_id,
    // Will always be an array of 1 endpoint
    endpoint: item.endpoints[0],
    callbackId: item.callback_id || undefined,
    callbackNotes: item.callback_notes || undefined,
    requiredSkills: item.required_skills,
    attributes: item.attributes,
    endpointAttributes: item.endpoint_attributes,
  }));

export const PreviewSettingsResponseDecoder: j.Decoder<DiallerGroupPreviewSettings> = j
  .object({
    enable_endpoint_selection: j.boolean(),
    push_preview_seconds: j.optional(j.number()),
    ring_out_seconds: j.optional(j.number()),
  })
  .map((item) => ({
    enableEndpointSelection: item.enable_endpoint_selection,
    pushPreviewSeconds: item.push_preview_seconds || 0,
    ringOutSeconds: item.ring_out_seconds || 0,
  }));

export const LeadAttemptResponseDecoder: j.Decoder<AttemptDetails> = j
  .object({
    attempt_id: j.number(),
    attempt_creation_context: j.union(
      j.oneOf(
        j.constant(AttemptCreationContext.Standard),
        j.constant(AttemptCreationContext.ManualPrepared),
        j.constant(AttemptCreationContext.ReceivedTransfer),
        j.constant(AttemptCreationContext.Callback),
        j.constant(AttemptCreationContext.Inbound),
        j.constant(AttemptCreationContext.UnknownInbound),
        j.constant(AttemptCreationContext.ManagedInboundQueue),
      ),
      j.constant(null),
    ),
    assigned_timestamp: j.union(j.string(), j.constant(null)),
    initiated_timestamp: j.union(j.string(), j.constant(null)),
    connected_timestamp: j.union(j.string(), j.constant(null)),
    disconnected_timestamp: j.union(j.string(), j.constant(null)),
    contact_id: j.union(j.string(), j.constant(null)),
    dialling_hours: j.union(DiallingHoursResponseDecoder, j.constant(null)),
    lead: j.union(AttemptLeadResponseDecoder, j.constant(null)),
    preview_settings: j.union(PreviewSettingsResponseDecoder, j.constant(null)),
    dispositions: j.union(j.array(DispositionItemResponseDecoder), j.constant(null)),
    public_holidays: j.union(j.array(PublicHolidayItemResponseDecoder), j.constant(null)),
    exclusion_lists: j.union(j.array(j.string()), j.constant(null)),
  })
  .map((item: any) => ({
    attemptId: item.attempt_id,
    creationContext: item.attempt_creation_context,
    assignedTimestamp: item.assigned_timestamp,
    initiatedTimestamp: item.initiated_timestamp,
    connectedTimestamp: item.connected_timestamp,
    disconnectedTimestamp: item.disconnected_timestamp,
    contactId: item.contact_id,
    diallingHours: item.dialling_hours || undefined,
    lead: item.lead || null,
    previewSettings: item.preview_settings || undefined,
    dispositions: item.dispositions ? item.dispositions.filter((d: Disposition) => d.code !== 'system') : undefined,
    publicHolidays: item.public_holidays || undefined,
    exclusionLists: item.exclusion_lists || undefined,
  }));
