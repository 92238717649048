import AddIcon from '@mui/icons-material/Add';
import ContactsIcon from '@mui/icons-material/Contacts';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import OberonCard from '~components/OberonCard';
import useDebounce from '~hooks/useDebounce';
import CreateAccessFilterModal from '~pages/SystemManagement/AccessFilterList/CreateAccessFilterModal';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { CreateAccessFilter } from '~pages/SystemManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { parseBoolean, pluraliseWord } from '~utils/Functions';

// Active is archived false, Archived is archived true
const enum FilterType {
  None = '',
  Active = 'false',
  Archived = 'true',
}

interface Query {
  search: string;
  filter: FilterType;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.None,
  },
  {
    label: 'Active',
    value: FilterType.Active,
  },
  {
    label: 'Archived',
    value: FilterType.Archived,
  },
];

const AccessFilterList = () => {
  const { pushNotification } = useNotification();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({ search: '', filter: FilterType.Active });
  const debouncedSearch = useDebounce(query.search, 500);
  const { loading, error, list, hasMore, getNextPage, create } = useAccessFilterSearch(debouncedSearch, {
    archived: query.filter === FilterType.None ? undefined : parseBoolean(query.filter),
  });
  const noSearchOrFilterSet = query.search === '' && query.filter === '';
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onQueryChange = useCallback(async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  }, []);

  const toggleCreateModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const addAccessFilter = async (data: CreateAccessFilter) => {
    setSubmittingData(true);

    try {
      await create(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created access filter ${data.name}`);
    setCreateModalOpen(false);
  };

  const displayList = useMemo(
    () =>
      list.map((item, index) => {
        const statusBackgroundColor = item.archived ? grey[600] : green[600];
        const statusName = item.archived ? 'Archived' : 'Active';

        let props = {
          ref: index === list.length - 1 ? lastDataElement : null,
          key: item.id,
          title: item.name,
          to: generatePath(Routes.accessFilterDetails.path, { accessFilterId: item.id.toString() }),
          subHeader: (
            <Chip
              sx={{
                marginTop: 2,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: statusBackgroundColor,
              }}
              label={statusName}
            />
          ),
          titleFontWeight: 400,
          // TODO: add new route for details view of access filters
          // to: generatePath(Routes.viewDiallerGroup.path, { diallerGroupId: item.id.toString() }),
          footer: (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {item.numAgents !== undefined && (
                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <HeadsetMicIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {item.numAgents.toLocaleString()} {pluraliseWord(item.numAgents, 'agent')}
                </Typography>
              )}

              {item.numCampaigns !== undefined && (
                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <SettingsPhoneIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {item.numCampaigns.toLocaleString()} {pluraliseWord(item.numCampaigns, 'campaign')}
                </Typography>
              )}

              {item.numGroups !== undefined && (
                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <ContactsIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {item.numGroups.toLocaleString()} {pluraliseWord(item.numGroups, 'group')}
                </Typography>
              )}

              {item.numAsyncQueues !== undefined && (
                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <ContactsIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {item.numAsyncQueues.toLocaleString()} {pluraliseWord(item.numAsyncQueues, 'async queue')}
                </Typography>
              )}
            </Box>
          ),
          footerBorderColor: item.archived ? grey[600] : green[600],
        };

        return <OberonCard {...props} />;
      }),
    [list, lastDataElement],
  );

  const filterListsDisplay = useMemo(
    () =>
      filterList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Filter'
            value={query.filter}
            onChange={onQueryChange}>
            {filterListsDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={toggleCreateModal}>
            Create Access Filter
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load access filters
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No access filters found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && noSearchOrFilterSet && (
              <EmptyState type='no-items-3' text='No access filters currently exist' />
            )}
          </Grid>
        </Grid>

        <CreateAccessFilterModal
          open={createModalOpen}
          submitting={submittingData}
          onClose={toggleCreateModal}
          onAccept={addAccessFilter}
        />
      </AsyncLoader>
    </>
  );
};

export default AccessFilterList;
