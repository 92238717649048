import ReplyIcon from '@mui/icons-material/Reply';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import useDebounce from '~hooks/useDebounce';
import useAsyncQueueSearch from '~pages/AsyncManagement/useAsyncQueueSearch';

interface QueueTransferPanelProps {
  onTransferToQueue: (queue: string) => void;
}

const CustomizedListItem = styled(ListItem)(({ theme }) => ({
  padding: 16,
}));

const CustomizedListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    userSelect: 'none',
    display: 'block',
    fontSize: 16,
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',

    /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const QueueTransferPanel = ({ onTransferToQueue }: QueueTransferPanelProps) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const { loading, error, list, hasMore, getNextPage } = useAsyncQueueSearch(debouncedSearch);
  const [boxRef, { height: boxHeight }] = useMeasure();
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const transferItemList = list.map((item, index) => (
    <CustomizedListItem
      ref={index === list.length - 1 ? lastDataElement : null}
      key={index}
      divider={index !== list.length - 1}>
      <CustomizedListItemText primary={item.title} />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => onTransferToQueue(item.queue)}
          size='medium'
          aria-label={`Transfer to ${item.title}`}>
          <ReplyIcon sx={{ transform: 'scaleX(-1)' }} />
        </IconButton>
      </ListItemSecondaryAction>
    </CustomizedListItem>
  ));

  return (
    <>
      <Box ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </Box>

      <Box sx={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        <AsyncLoader isLoading={loading}>
          {list.length > 0 && (
            <>
              <List sx={{ padding: 0 }}>{transferItemList}</List>
              {loading && list.length > 0 && <DotLoader align='center' />}

              {!loading && !hasMore && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No more results to display
                </Typography>
              )}

              {error && list.length > 0 && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load queues
                </Typography>
              )}
            </>
          )}

          {list.length === 0 && (
            <>
              {!error && !debouncedSearch && (
                <Typography marginTop={1} variant='body1' component='p' align='center'>
                  There are no queue's to transfer to.
                </Typography>
              )}

              {!error && debouncedSearch && (
                <Typography marginTop={1} variant='body1' component='p' align='center'>
                  No search results found.
                </Typography>
              )}

              {error && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load queues
                </Typography>
              )}
            </>
          )}
        </AsyncLoader>
      </Box>
    </>
  );
};

export default QueueTransferPanel;
