import { deflate } from 'pako';
// @ts-ignore
import { v4 as uuid } from 'uuid';

export const getDeobfucatedValue = async (
  authEndpoint: string, // Shall be provided by environment.
  deobfuscateEndpoint: string, // Shall be provided by environment.
  contactId: string,
  token: string,
): Promise<string> => {
  const jwt = await fetch(authEndpoint, {
    credentials: 'include',
    method: 'GET',
    headers: {
      RequestVerb: 'GET',
      ProxyName: 'Customers_V2',
    },
  }).then((res) => res.text());

  const compressedJwt = 'compressedToBase64' + Buffer.from(deflate(Buffer.from(jwt))).toString('base64');

  const result = await fetch(deobfuscateEndpoint, {
    method: 'POST',
    headers: {
      'BNZAPI-Request-Id': uuid(),
      'Authorization': compressedJwt,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify({ contactId, token }),
  }).then((res) => res.json());

  return result.deobfuscatedValue;
};
