import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ListItemButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, MouseEvent, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { DotLoader } from '~components/DotLoader';
import useDebounce from '~hooks/useDebounce';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

const MaybeTooltip = ({ tooltip, children }: { tooltip?: string; children: ReactNode }) => {
  if (!tooltip) return <>{children}</>;

  return (
    <Tooltip title={tooltip} arrow placement='bottom-start'>
      <span>{children}</span>
    </Tooltip>
  );
};

const AccessFilterMenu = () => {
  const { accessFilter, setUserAccessFilter } = useUserPreferences();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchor);
  const { loading, list, error, getNextPage, hasMore } = useAccessFilterSearch(debouncedSearch, {
    archived: false,
    shouldFetch: menuOpen,
  });
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );
  let filterColor = '';
  let filterTooltip = '';

  if (accessFilter && accessFilter.archived) {
    filterColor = red[500];
    filterTooltip = 'Your current filter has been archived.';
  } else if (accessFilter) {
    filterColor = blue[500];
  }

  const onQueryChange = useCallback(async (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
  }, []);

  const handleUserMenuClick = (e: MouseEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleUserMenuClose = () => {
    setMenuAnchor(null);
    setSearch('');
  };

  const displayList = useMemo(
    () =>
      list.map((item, index) => {
        return (
          <ListItemButton
            ref={index === list.length - 1 ? lastDataElement : null}
            key={item.id}
            selected={accessFilter?.id === item.id}
            onClick={() => setUserAccessFilter(item)}>
            <ListItemText>{item.name}</ListItemText>
          </ListItemButton>
        );
      }),
    [list, lastDataElement, accessFilter],
  );

  return (
    <>
      <Button
        sx={{
          'maxWidth': 240,
          'padding': '3px 16px',
          'borderRadius': 1,
          'textTransform': 'none',
          'fontSize': 14,
          'color': 'white',
          ':hover': {
            color: '#ffffff',
            backgroundColor: '#ffffff12',
          },
        }}
        startIcon={
          <MaybeTooltip tooltip={filterTooltip}>
            <Avatar sx={{ backgroundColor: filterColor }}>
              {!accessFilter && <FilterAltOffIcon />}
              {accessFilter && <FilterAltIcon />}
            </Avatar>
          </MaybeTooltip>
        }
        onClick={handleUserMenuClick}
        endIcon={<KeyboardArrowDownIcon sx={{ opacity: 0.5 }} />}>
        <Box sx={{ maxWidth: 140, textAlign: 'left' }}>
          <Typography
            fontSize={14}
            lineHeight={1}
            paddingTop={1}
            minWidth={0}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
            fontWeight='medium'>
            Access Filters
          </Typography>

          <Typography
            fontSize={12}
            lineHeight={1.75}
            color='#ffffff80'
            minWidth={0}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
            fontWeight='regular'>
            {accessFilter?.name || 'none'}
          </Typography>
        </Box>
      </Button>

      <Menu
        sx={{
          '& .MuiMenu-list': {
            padding: 0,
            width: 240,
          },
        }}
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={handleUserMenuClose}>
        <Box sx={{ padding: 1 }}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            value={search}
            onChange={onQueryChange}
            onKeyDown={(e) => {
              // Note: This solves the below issue when a text field component is used within the Menu component as a
              // child.
              // https://github.com/mui/material-ui/issues/19116
              e.stopPropagation();
            }}
          />
        </Box>

        <Divider component='hr' />

        <Box sx={{ paddingTop: 0.5, paddingLeft: 1, paddingRight: 1, paddingBottom: 0.5 }}>
          <Link
            component='button'
            underline='none'
            aria-disabled={!accessFilter}
            onClick={() => {
              if (accessFilter) {
                setUserAccessFilter();
              }
            }}>
            Clear
          </Link>
        </Box>

        <Divider component='hr' />

        {loading && list.length === 0 && <DotLoader align='center' />}

        {!loading && (
          <Box sx={{ height: 400, overflow: 'auto' }}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load queues
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && debouncedSearch !== '' && (
              <Typography sx={{ marginTop: 1 }} variant='body2' align='center' color='textSecondary'>
                No access filters found matching your search criteria
              </Typography>
            )}

            {list.length === 0 && debouncedSearch === '' && (
              <Typography sx={{ marginTop: 1 }} variant='body2' align='center' color='textSecondary'>
                No access filters currently exist
              </Typography>
            )}
          </Box>
        )}
      </Menu>
    </>
  );
};

export default AccessFilterMenu;
