import * as j from '@mojotech/json-type-validation';

export interface DiallerGroupCampaign {
  entryId: number;
  campaignId: number;
  name: string;
  archived: string | null;
  startTime: string | null;
  endTime: string | null;
  priority: number;
}

export type AddCampaignGroupAssignment = Omit<DiallerGroupCampaign, 'entryId' | 'name' | 'archived'>;
export type UpdateCampaignGroupAssignment = Omit<DiallerGroupCampaign, 'campaignId' | 'name' | 'archived'>;

export interface DiallerGroupCampaignsResponse {
  nextPageUrl: string | null;
  campaigns: DiallerGroupCampaign[];
}

const DiallerGroupCampaignResponseDecoder: j.Decoder<DiallerGroupCampaign> = j
  .object({
    id: j.number(),
    campaign_id: j.number(),
    name: j.string(),
    archived: j.union(j.string(), j.constant(null)),
    start_time: j.union(j.string(), j.constant(null)),
    end_time: j.union(j.string(), j.constant(null)),
    priority: j.number(),
  })
  .map((item) => ({
    entryId: item.id,
    campaignId: item.campaign_id,
    name: item.name,
    archived: item.archived,
    startTime: item.start_time,
    endTime: item.end_time,
    priority: item.priority,
  }));

export const DiallerGroupCampaignsResponseDecoder: j.Decoder<DiallerGroupCampaignsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    campaigns: j.array(DiallerGroupCampaignResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    campaigns: item.campaigns,
  }));
