import * as j from '@mojotech/json-type-validation';

import {
  CampaignType,
  DiallerGroup,
  DiallerGroupPredictiveSettings,
  DiallerGroupPreviewSettings,
  DiallerType,
} from '~pages/CampaignManagement/domain';

const DiallerGroupPreviewSettingsDecoder: j.Decoder<DiallerGroupPreviewSettings> = j
  .object({
    enable_endpoint_selection: j.boolean(),
    push_preview_seconds: j.number(),
    ring_out_seconds: j.number(),
  })
  .map((item) => ({
    enableEndpointSelection: item.enable_endpoint_selection,
    pushPreviewSeconds: item.push_preview_seconds,
    ringOutSeconds: item.ring_out_seconds,
  }));

const DiallerGroupPredictiveSettingsDecoder: j.Decoder<DiallerGroupPredictiveSettings> = j
  .object({
    ring_time_seconds: j.number(),
    hold_drop_seconds: j.number(),
    max_lines_per_agent: j.number(),
    lookahead_time_seconds: j.number(),
    num_reserved_agents: j.number(),
    outbound_queue: j.optional(j.union(j.string(), j.constant(null))),
  })
  .map((item) => ({
    ringTimeSeconds: item.ring_time_seconds,
    holdDropSeconds: item.hold_drop_seconds,
    maxLinesPerAgent: item.max_lines_per_agent,
    lookaheadTimeSeconds: item.lookahead_time_seconds,
    numReservedAgents: item.num_reserved_agents,
    outboundQueue: item.outbound_queue,
  }));

export const DiallerGroupResponseDecoder: j.Decoder<DiallerGroup> = j
  .object({
    dialler_group_id: j.number(),
    name: j.string(),
    description: j.string(),
    archived: j.optional(j.string()),
    dialler_type: j.string(),
    campaign_type: j.string(),
    enable_manual_outbound: j.boolean(),
    manual_outbound_require_details: j.boolean(),
    enable_prepared_outbound_hangup: j.boolean(),
    enable_manual_outbound_when_offline: j.boolean(),
    system_disposed_inbound_calls: j.boolean(),
    voicemail_message_arn: j.union(j.string(), j.constant(null)),
    preview_settings: j.optional(DiallerGroupPreviewSettingsDecoder),
    predictive_settings: j.optional(DiallerGroupPredictiveSettingsDecoder),
  })
  .map((item) => ({
    id: item.dialler_group_id,
    name: item.name,
    description: item.description,
    archived: item.archived,
    diallerType: item.dialler_type as DiallerType,
    campaignType: item.campaign_type as CampaignType,
    enableManualOutbound: item.enable_manual_outbound,
    manualOutboundRequireDetails: item.manual_outbound_require_details,
    enablePreparedOutboundHangup: item.enable_prepared_outbound_hangup,
    enableManualOutboundWhenOffline: item.enable_manual_outbound_when_offline,
    systemDisposedInboundCalls: item.system_disposed_inbound_calls,
    voicemailMessageARN: item.voicemail_message_arn || undefined,
    previewSettings: item.preview_settings,
    predictiveSettings: item.predictive_settings,
    numCampaigns: undefined,
    numLeads: undefined,
    numAgents: undefined,
  }));
