import * as j from '@mojotech/json-type-validation';

import { CampaignType, DiallerGroup, DiallerType } from '~pages/CampaignManagement/domain';

export interface DiallerGroupResponse {
  nextPageUrl: string | null;
  groups: DiallerGroup[];
}

const DiallerGroupItemResponseDecoder: j.Decoder<DiallerGroup> = j
  .object({
    dialler_group_id: j.number(),
    name: j.string(),
    description: j.string(),
    archived: j.optional(j.string()),
    dialler_type: j.string(),
    campaign_type: j.string(),
    enable_manual_outbound: j.boolean(),
    manual_outbound_require_details: j.boolean(),
    enable_prepared_outbound_hangup: j.boolean(),
    enable_manual_outbound_when_offline: j.boolean(),
    system_disposed_inbound_calls: j.boolean(),
    voicemail_message_arn: j.union(j.string(), j.constant(null)),
    num_campaigns: j.number(),
    num_leads: j.number(),
    num_agents: j.number(),
  })
  .map((item) => ({
    id: item.dialler_group_id,
    name: item.name,
    description: item.description,
    archived: item.archived,
    diallerType: item.dialler_type as DiallerType,
    campaignType: item.campaign_type as CampaignType,
    enableManualOutbound: item.enable_manual_outbound,
    manualOutboundRequireDetails: item.manual_outbound_require_details,
    enablePreparedOutboundHangup: item.enable_prepared_outbound_hangup,
    enableManualOutboundWhenOffline: item.enable_manual_outbound_when_offline,
    systemDisposedInboundCalls: item.system_disposed_inbound_calls,
    voicemailMessageARN: item.voicemail_message_arn || undefined,
    previewSettings: undefined,
    predictiveSettings: undefined,
    numCampaigns: item.num_campaigns,
    numLeads: item.num_leads,
    numAgents: item.num_agents,
  }));

export const DiallerGroupsResponseDecoder: j.Decoder<DiallerGroupResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    groups: j.array(DiallerGroupItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    groups: item.groups,
  }));
