import * as j from '@mojotech/json-type-validation';

import { Disposition, LeadStatusType, OutcomeType } from '~pages/CampaignManagement/domain';

export enum AttemptStatusType {
  Initiated = 'initiated',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface EndpointAttributes {
  type: string;
  attributes: Dictionary<string>;
}

export interface DiallableLead {
  id: number;
  externalId: string;
  name: string;
  status: LeadStatusType;
  timezone: string;
  campaignId: number;
  endpoint: string;
  callbackId: number | undefined;
  callbackNotes: string | undefined;
  attributes: Dictionary<any>;
  endpointAttributes: Dictionary<EndpointAttributes>;
  requiredSkills: string;
}

export interface OutcomeDisposition {
  contactId: string | undefined;
  dispositionCode: string;
  dispositionSubCode: string;
  hasSystemIssue: boolean;
  systemIssueDescription?: string;
  metadata?: Dictionary<string>;
  callback?: Callback;
  exclusionList?: string;
}

export interface Callback {
  forAgent: string | null;
  scheduled: string;
  endpoint: string;
  notes: string;
}

export interface ContactOutcome {
  dispositionCode: string;
  dispositionSubCode: string;
  hasSystemIssue: boolean;
  systemIssueDescription?: string;
  attributes: Dictionary<any>;
}

export const GetCampaignDispositionResponseDecoder: j.Decoder<Disposition[]> = j
  .array(
    j.object({
      title: j.string(),
      description: j.string(),
      outcome: j.oneOf(
        j.constant(OutcomeType.Contacted),
        j.constant(OutcomeType.Callback),
        j.constant(OutcomeType.NoAnswer),
        j.constant(OutcomeType.AnsweringMachine),
        j.constant(OutcomeType.Engaged),
        j.constant(OutcomeType.InvalidEndpoint),
        j.constant(OutcomeType.HoldDropped),
        j.constant(OutcomeType.Excluded),
        j.constant(OutcomeType.Skipped),
        j.constant(OutcomeType.Removed),
        j.constant(OutcomeType.RequiredAgentUnavailable),
        j.constant(OutcomeType.AssignmentExpired),
        j.constant(OutcomeType.Error),
        j.constant(OutcomeType.Fatal),
        j.constant(OutcomeType.AwaitingCallback),
      ),
      code: j.string(),
      sub_code: j.string(),
      attributes: j.array(
        j.object({
          attribute: j.string(),
          value: j.string(),
        }),
      ),
    }),
  )
  .map((dispositions) =>
    dispositions.map((disposition) => ({
      title: disposition.title,
      description: disposition.description,
      outcome: disposition.outcome,
      code: disposition.code,
      subCode: disposition.sub_code,
      attributes: disposition.attributes.map((attr: any) => ({
        attribute: attr.attribute,
        value: attr.value,
      })),
    })),
  );

export const GetTimezonesResponseDecoder: j.Decoder<string[]> = j.array(j.string());
