import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getConnectQueueStats } from './api';
import { ConnectStatsResponse } from './domain';

type Options = {
  shouldFetch?: boolean;
};

const useConnectStats = (options: Options = { shouldFetch: true }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [dict, setDict] = useState<ConnectStatsResponse>({ queueStats: {}, routingProfileStats: {} });
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const shouldFetch = options.shouldFetch ?? true;

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: ConnectStatsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getConnectQueueStats(axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setDict(resp);
    setLoading(false);
  }, []);

  useEffect(() => {
    setDict({ queueStats: {}, routingProfileStats: {} });
  }, [shouldFetch]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setError(false);

      let resp: ConnectStatsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getConnectQueueStats(axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setDict(resp);
      setLoading(false);
    };

    if (shouldFetch) {
      load();
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [shouldFetch]);

  return {
    loading,
    error,
    queueStats: dict.queueStats,
    routingProfileStats: dict.routingProfileStats,
    reload,
  };
};

export default useConnectStats;
