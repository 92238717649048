import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Agent } from '~pages/SystemManagement/domain';

import { getAgents, getAgentsByNextUrl } from './api';
import { AgentsResponse } from './domain';

type Options = {
  accessFilterId?: number;
  diallerGroupId?: string;
  campaignId?: number;
  allocated?: boolean;
  shouldFetch?: boolean;
};

const useAgentList = (
  search: string,
  options: Options = { diallerGroupId: undefined, campaignId: undefined, allocated: undefined, shouldFetch: true },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const accessFilterId = options.accessFilterId;
  const diallerGroupId = options.diallerGroupId;
  const campaignId = options.campaignId;
  const allocated = options.allocated;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAgentsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setAgents((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: AgentsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAgents(search, diallerGroupId, campaignId, allocated, accessFilterId, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setAgents(resp.agents);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search, diallerGroupId, campaignId, allocated, accessFilterId]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  useEffect(() => {
    setAgents([]);
  }, [search, diallerGroupId, campaignId, allocated, accessFilterId, shouldFetch]);

  useEffect(() => {
    const load = async (
      search: string,
      diallerGroupId: string | undefined,
      campaignId: number | undefined,
      allocated: boolean | undefined,
      accessFilterId: number | undefined,
    ) => {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAgents(search, diallerGroupId, campaignId, allocated, accessFilterId, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setAgents((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search, diallerGroupId, campaignId, allocated, accessFilterId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, diallerGroupId, campaignId, allocated, accessFilterId, shouldFetch]);

  return { loading, error, agents, hasMore, reload, intersectionObserverRef };
};

export default useAgentList;
