import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ChangeEvent, useCallback, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import useDebounce from '~hooks/useDebounce';
import useCampaignSearch from '~pages/CampaignManagement/CampaignList/useCampaignSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

type Props = {
  selectedCampaigns?: number[];
  update: (value: number[]) => void;
};

export const CampaignFilter = ({ selectedCampaigns = [], update }: Props) => {
  const { accessFilter } = useUserPreferences();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading,
    list,
    error,
    hasMore,
    intersectionObserverRef: lastDataElement,
  } = useCampaignSearch(debouncedSearch, {
    shouldFetch: open,
    accessFilterId: accessFilter?.id,
  });

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onSearchChange = useCallback(async (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
  }, []);

  const selectionToggle = (id: number) => {
    const index = selectedCampaigns.indexOf(id);
    const newList = [...selectedCampaigns];

    if (index === -1) {
      newList.push(id);
    } else {
      newList.splice(index, 1);
    }

    update(newList);
  };

  const onRemoveAll = () => {
    update([]);
  };

  const displayList = list.map((item, index) => (
    <ListItem key={item.id} ref={index === list.length - 1 ? lastDataElement : null} disablePadding>
      <ListItemButton onClick={() => selectionToggle(item.id)} dense>
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={Boolean(selectedCampaigns.find((s) => s === item.id))}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={item.name} secondary={item.archived ? '(Archived)' : undefined} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={toggleOpen}
          size='small'
          endIcon={open ? <RemoveIcon fontSize='small' /> : <AddIcon fontSize='small' />}>
          Selected Campaigns {selectedCampaigns.length > 0 ? `(${selectedCampaigns.length})` : null}
        </Button>

        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={onRemoveAll}
          size='small'
          disabled={selectedCampaigns.length === 0}>
          Clear
        </Button>
      </Box>

      <Collapse in={open}>
        <Box>
          <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={search}
              onChange={onSearchChange}
            />
          </Box>

          <Divider variant='fullWidth' component='hr' />

          <Box sx={{ height: 400, overflow: 'auto' }}>
            <AsyncLoader isLoading={loading && list.length === 0}>
              {displayList.length > 0 && (
                <>
                  <List sx={{ paddingTop: 0, paddingBottom: 0 }}>{displayList}</List>
                  {loading && displayList.length > 0 && <DotLoader align='center' />}

                  {!loading && !hasMore && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      No more results to display
                    </Typography>
                  )}

                  {error && displayList.length > 0 && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      Failed to load campaigns
                    </Typography>
                  )}
                </>
              )}

              {displayList.length === 0 && debouncedSearch !== '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No campaigns found matching your search criteria.
                </Typography>
              )}

              {displayList.length === 0 && selectedCampaigns.length === 0 && debouncedSearch === '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No campaigns currently exist
                </Typography>
              )}

              {displayList.length === 0 && selectedCampaigns.length > 0 && debouncedSearch === '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No more campaigns to choose from.
                </Typography>
              )}
            </AsyncLoader>
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default CampaignFilter;
