import * as j from '@mojotech/json-type-validation';

export type DeobfuscationResponse = {
  value: string;
};

export const DeobfuscationResponseDecoder: j.Decoder<string> = j
  .object({
    value: j.string(),
  })
  .map((item) => item.value);
