import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AccessFilter, CreateAccessFilter } from '~pages/SystemManagement/domain';

import { createAccessFilter, getAccessFilters, getAccessFiltersByNextUrl } from './api';
import { AccessFilterResponse } from './domain';

type Options = {
  archived?: boolean;
  shouldFetch?: boolean;
};

const useAccessFilterSearch = (search: string, options: Options = { archived: undefined, shouldFetch: true }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<AccessFilter[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const archived = options.archived;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: AccessFilterResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAccessFiltersByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.filters]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: AccessFilterResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAccessFilters(search, archived, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.filters);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search, archived]);

  const create = useCallback(async (data: CreateAccessFilter) => {
    try {
      await createAccessFilter(data);
    } catch (e) {
      // Do nothing
      return Promise.reject(e);
    }

    await reload();
  }, []);

  useEffect(() => {
    setList([]);
  }, [search, archived, shouldFetch]);

  useEffect(() => {
    const load = async (search: string, archived: boolean | undefined) => {
      setLoading(true);
      setError(false);

      let resp: AccessFilterResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAccessFilters(search, archived, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.filters]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search, archived);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, archived, shouldFetch]);

  return { loading, error, list, hasMore, getNextPage, create };
};

export default useAccessFilterSearch;
