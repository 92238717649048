import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addDiallerGroupCampaign,
  getDiallerGroupCampaigns,
  getDiallerGroupCampaignsByNextUrl,
  removeCampaignFromGroup,
  updateDiallerGroupCampaign,
} from './api';
import {
  AddCampaignGroupAssignment,
  DiallerGroupCampaign,
  DiallerGroupCampaignsResponse,
  UpdateCampaignGroupAssignment,
} from './domain';

const useDiallerGroupCampaignSearch = (
  diallerGroupId: number,
  search: string,
  archived: boolean | undefined,
  shouldFetch: boolean = true,
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<DiallerGroupCampaign[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: DiallerGroupCampaignsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroupCampaignsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: DiallerGroupCampaignsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getDiallerGroupCampaigns(diallerGroupId, search, archived, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.campaigns);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [diallerGroupId, search, archived]);

  const add = useCallback(
    async (data: AddCampaignGroupAssignment) => {
      try {
        await addDiallerGroupCampaign(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );
  const update = useCallback(
    async (data: UpdateCampaignGroupAssignment) => {
      try {
        await updateDiallerGroupCampaign(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );
  const remove = useCallback(
    async (entryId: number) => {
      try {
        await removeCampaignFromGroup(diallerGroupId, entryId);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  useEffect(() => {
    setList([]);
  }, [diallerGroupId, search, archived, shouldFetch]);

  useEffect(() => {
    const load = async (diallerGroupId: number, search: string, archived: boolean | undefined) => {
      setLoading(true);
      setError(false);

      let resp: DiallerGroupCampaignsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroupCampaigns(diallerGroupId, search, archived, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(diallerGroupId, search, archived);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [diallerGroupId, search, archived, shouldFetch]);

  return { loading, error, list, hasMore, getNextPage, add, update, remove };
};

export default useDiallerGroupCampaignSearch;
