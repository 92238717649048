import * as j from '@mojotech/json-type-validation';

export interface ConnectQueueStats {
  id: string;
  name: string;
  abandoned: number;
  callsIn: number;
  callsOut: number;
  callsWaiting: number;
  longestWaitTimeSec: number;
}

export interface ConnectRoutingProfileStats {
  id: string;
  name: string;
  agentsAvailable: number;
  agentsOnline: number;
  /** key is the ID of the queue */
  queues: { [key: string]: string };
}

export type ConnectStatsResponse = {
  /** key is the ID of the queue */
  queueStats: { [key: string]: ConnectQueueStats };
  /** key is the ID of the routing profile */
  routingProfileStats: { [key: string]: ConnectRoutingProfileStats };
};

const ConnectQueueStatsItemResponseDecoder: j.Decoder<ConnectQueueStats> = j
  .object({
    queue_id: j.string(),
    queue_name: j.string(),
    abandoned: j.number(),
    calls_in: j.number(),
    calls_out: j.number(),
    calls_waiting: j.number(),
    longest_wait_time_sec: j.number(),
  })
  .map((item) => ({
    id: item.queue_id,
    name: item.queue_name,
    abandoned: item.abandoned,
    callsIn: item.calls_in,
    callsOut: item.calls_out,
    callsWaiting: item.calls_waiting,
    longestWaitTimeSec: item.longest_wait_time_sec,
  }));

const ConnectRoutingProfileStatsItemResponseDecoder: j.Decoder<ConnectRoutingProfileStats> = j
  .object({
    routing_profile_id: j.string(),
    routing_profile_name: j.string(),
    agents_available: j.number(),
    agents_online: j.number(),
    queues: j.dict(j.string()),
  })
  .map((item) => ({
    id: item.routing_profile_id,
    name: item.routing_profile_name,
    agentsAvailable: item.agents_available,
    agentsOnline: item.agents_online,
    queues: item.queues,
  }));

export const ConnectQueueStatsResponseDecoder: j.Decoder<ConnectStatsResponse> = j
  .object({
    queue_stats: j.dict(ConnectQueueStatsItemResponseDecoder),
    routing_profile_stats: j.dict(ConnectRoutingProfileStatsItemResponseDecoder),
  })
  .map((item) => ({
    queueStats: item.queue_stats,
    routingProfileStats: item.routing_profile_stats,
  }));
