import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import SectionCard from '~components/SectionCard';
import {
  CampaignType,
  DiallerGroup,
  DiallerType,
  UpdateDiallerGroup,
  typeIdsToDisplayText,
} from '~pages/CampaignManagement/domain';

interface Form {
  name: string;
  description: string;
  enableManualOutboundWhenAvailable: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  systemDisposedInboundCalls: boolean;
  // State for checkbox, not to be sent to backend
  enableVoicemailMessage: boolean;
  voicemailMessageARN: string;
}

interface GeneralSettingsFormProps {
  diallerGroup: DiallerGroup;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  update: (data: Partial<UpdateDiallerGroup>) => Promise<void>;
}

const GeneralSettingsForm = ({ diallerGroup, isEdit, submitting, toggleEdit, update }: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      enableManualOutboundWhenAvailable: false,
      manualOutboundRequireDetails: false,
      enablePreparedOutboundHangup: false,
      enableManualOutboundWhenOffline: false,
      systemDisposedInboundCalls: false,
      enableVoicemailMessage: false,
      voicemailMessageARN: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const enableManualOutboundWhenAvailableWatch = watch('enableManualOutboundWhenAvailable');
  const enableManualOutboundWhenOfflineWatch = watch('enableManualOutboundWhenOffline');
  const showManualOutboundOptions = enableManualOutboundWhenAvailableWatch || enableManualOutboundWhenOfflineWatch;
  const enableVoicemailMessageWatch = watch('enableVoicemailMessage');

  useEffect(() => {
    if (isEdit) {
      setValue('name', diallerGroup.name);
      setValue('description', diallerGroup.description || '');
      // Note: disabled as part of predictive
      setValue(
        'enableManualOutboundWhenAvailable',
        diallerGroup.campaignType === CampaignType.Predictive ? false : diallerGroup.enableManualOutbound,
      );
      setValue('manualOutboundRequireDetails', diallerGroup.manualOutboundRequireDetails);
      setValue('enablePreparedOutboundHangup', diallerGroup.enablePreparedOutboundHangup);
      setValue('enableManualOutboundWhenOffline', diallerGroup.enableManualOutboundWhenOffline);
      setValue('systemDisposedInboundCalls', diallerGroup.systemDisposedInboundCalls);
      setValue('enableVoicemailMessage', Boolean(diallerGroup.voicemailMessageARN));
      setValue('voicemailMessageARN', diallerGroup.voicemailMessageARN || '');
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  useEffect(() => {
    if (!showManualOutboundOptions) {
      setValue('manualOutboundRequireDetails', false);
      setValue('enablePreparedOutboundHangup', false);
    }
  }, [showManualOutboundOptions]);

  const onSubmit = handleSubmit((data: Form) => {
    let submitData: Partial<UpdateDiallerGroup> = {
      name: data.name,
      description: data.description,
      enableManualOutbound: data.enableManualOutboundWhenAvailable,
      manualOutboundRequireDetails: data.manualOutboundRequireDetails,
      enablePreparedOutboundHangup: data.enablePreparedOutboundHangup,
      enableManualOutboundWhenOffline: data.enableManualOutboundWhenOffline,
      systemDisposedInboundCalls: data.systemDisposedInboundCalls,
    };

    if (data.enableVoicemailMessage) {
      submitData = {
        ...submitData,
        voicemailMessageARN: data.voicemailMessageARN,
      };
    }

    update(submitData);
  });

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem stacked={isExtraSmall} disableMargin title='Dialler Group Name' value={diallerGroup.name} />
          <DataItem
            stacked={isExtraSmall}
            title='Dialler Type'
            value={typeIdsToDisplayText[diallerGroup.diallerType]}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Campaign Type'
            value={typeIdsToDisplayText[diallerGroup.campaignType]}
          />
          <DataItem stacked={isExtraSmall} title='Description' value={diallerGroup.description || '-'} />

          {/* TODO: Remove check when we support manual outbound within predictive */}
          {diallerGroup.diallerType !== DiallerType.SIP && (
            <>
              {diallerGroup.campaignType !== CampaignType.Predictive && (
                <DataItem
                  stacked={isExtraSmall}
                  title='Enable Manual Outbound When Available'
                  tooltip='Allows agents to perform manual outbound calls when in an Available state.'
                  value={diallerGroup.enableManualOutbound ? 'Yes' : 'No'}
                />
              )}

              <DataItem
                stacked={isExtraSmall}
                title='Enable Manual Outbound When Offline'
                tooltip='Allows agents to perform manual outbound calls when in an offline or not routable state.'
                value={diallerGroup.enableManualOutboundWhenOffline ? 'Yes' : 'No'}
              />

              {(diallerGroup.enableManualOutbound === true || diallerGroup.enableManualOutboundWhenOffline) && (
                <>
                  <DataItem
                    stacked={isExtraSmall}
                    title='Manual Outbound Require Details'
                    value={diallerGroup.manualOutboundRequireDetails ? 'Yes' : 'No'}
                  />

                  <DataItem
                    stacked={isExtraSmall}
                    title='Enable Prepared Outbound Hangup'
                    tooltip='Allows agents to hangup calls they have manually prepared before it has been connected.'
                    value={diallerGroup.enablePreparedOutboundHangup ? 'Yes' : 'No'}
                  />
                </>
              )}

              <DataItem
                stacked={isExtraSmall}
                title='System Dispose Inbound Calls'
                tooltip='Generic system disposition is provided to all inbound calls.'
                value={diallerGroup.systemDisposedInboundCalls ? 'Yes' : 'No'}
              />

              {diallerGroup.voicemailMessageARN && (
                <DataItem
                  stacked={isExtraSmall}
                  title='Voicemail Message ARN'
                  tooltip='Allows agents to leave an automated voicemail message when prompted by a customers voicemail.'
                  value={diallerGroup.voicemailMessageARN}
                />
              )}
            </>
          )}
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Name'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Description'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            {/* TODO: Remove check when we support manual outbound within predictive */}
            {diallerGroup.diallerType !== DiallerType.SIP && (
              <>
                {diallerGroup.campaignType !== CampaignType.Predictive && (
                  <Grid item xs={12}>
                    <ControlledCheckbox
                      name='enableManualOutboundWhenAvailable'
                      label='Enable Manual Outbound When Available?'
                      control={control}
                      disabled={submitting}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='enableManualOutboundWhenOffline'
                    label='Enable Manual Outbound When Offline?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>

                {showManualOutboundOptions && (
                  <>
                    <Grid item xs={12}>
                      <ControlledCheckbox
                        name='manualOutboundRequireDetails'
                        label='Manual Outbound Require Details?'
                        control={control}
                        disabled={submitting}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledCheckbox
                        name='enablePreparedOutboundHangup'
                        label='Enable Prepared Outbound Hangup?'
                        control={control}
                        disabled={submitting}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='systemDisposedInboundCalls'
                    label='System Dispose Inbound Calls?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='enableVoicemailMessage'
                    label='Enable Voicemail Message?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>

                {enableVoicemailMessageWatch && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name='voicemailMessageARN'
                        control={control}
                        rules={{
                          required: 'Voicemail message ARN is required.',
                          validate: (value) => {
                            if (!value || (value && value.length === 0)) return undefined;

                            if (!value.startsWith('arn:')) {
                              return 'Voicemail message ARN must be a valid ARN.';
                            }

                            return undefined;
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant='outlined'
                            label='Voicemail Message ARN'
                            disabled={submitting}
                            required={true}
                            error={Boolean(errors.voicemailMessageARN)}
                            helperText={errors.voicemailMessageARN?.message}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;
