import LoadingButton from '@mui/lab/LoadingButton';
import { ListItemButton, Step, StepLabel, Stepper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import blueGrey from '@mui/material/colors/blueGrey';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import useLeadList from '~hooks/useLeadList';
import { Lead } from '~hooks/useLeadList/domain';
import SelectedLead from '~pages/Callbacks/CreateCallbackModal/SelectedLead';
import { CreateCallback } from '~pages/Callbacks/useCallbacks/domain';
import { getCampaignById, getPublicHolidays } from '~pages/CampaignManagement/api';
import { DiallingHours, LeadStatusType, PublicHoliday, leadStatusColor } from '~pages/CampaignManagement/domain';
import { Agent } from '~pages/SystemManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

const Progress = {
  SelectLead: 0,
  SelectCallbackDetails: 1,
} as const;

const steps = [
  {
    label: 'Select Lead',
    value: Progress.SelectLead,
  },
  {
    label: 'Callback Details',
    value: Progress.SelectCallbackDetails,
  },
];

const EndpointType = {
  New: 'new',
  Existing: 'existing',
} as const;

interface Form {
  lead: Lead | null;
  endpointType: typeof EndpointType[keyof typeof EndpointType];
  endpoint: string | null;
  forAgent: Agent | null;
  scheduled: string;
  notes: string;
}

interface CreateCallbackModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateCallback) => void;
  onClose: () => void;
}

type SearchOption = {
  lead: string;
  agent: string;
};

const BackButton = styled(Button)(({ theme }) => ({
  'color': theme.palette.getContrastText(blueGrey[900]),
  'backgroundColor': blueGrey[900],
  ':hover': {
    backgroundColor: blueGrey[800],
  },
  ':disabled': {
    opacity: 0.3,
    color: theme.palette.getContrastText(blueGrey[900]),
  },
}));

const fetchDiallingHoursOrUndefined = async (campaignId: number) => {
  try {
    const campaign = await getCampaignById(campaignId);

    if (
      campaign !== undefined &&
      campaign.leadEngineSettings !== undefined &&
      campaign.leadEngineSettings.diallingHours !== undefined
    ) {
      return campaign.leadEngineSettings.diallingHours;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

const fetchPublicHolidaysOrEmptyArray = async (timezone: string) => {
  try {
    return await getPublicHolidays(timezone);
  } catch (e) {
    return [];
  }
};

const blockCreationList = [LeadStatusType.Excluded];

const endpointTypeList = [
  {
    label: 'Existing',
    value: EndpointType.Existing,
  },
  {
    label: 'New',
    value: EndpointType.New,
  },
];

// Used for disabling dates within disableDates function
const dayToWeekdayNumber: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Used for showing error messages for selected date range within onValidation function
const weekdayNumberToDay: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const searchDefault: SearchOption = Object.freeze({ lead: '', agent: '' });

const CreateCallbackModal = ({ open, submitting, onAccept, onClose }: CreateCallbackModalProps) => {
  const { accessFilter } = useUserPreferences();
  const { pushNotification } = useNotification();
  const [progress, setProgress] = useState<typeof Progress[keyof typeof Progress]>(Progress.SelectLead);
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([]);
  const [diallingHours, setDiallingHours] = useState<DiallingHours | undefined>(undefined);
  const [search, setSearch] = useState<{ lead: string; agent: string }>(searchDefault);
  const debouncedSearchLead = useDebounce(search.lead, 500);
  const debouncedSearchAgent = useDebounce(search.agent, 500);
  const isLoading = submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      lead: null,
      endpointType: EndpointType.Existing,
      endpoint: null,
      forAgent: null,
      scheduled: '',
      notes: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });
  const watchLead = watch('lead');
  const watchEndpointType = watch('endpointType');
  const endpointsList = watchLead !== null && watchLead !== undefined ? watchLead.endpoints : [];
  const {
    loading: fetchingLeads,
    error: leadsFetchError,
    list: leads,
    hasMore: hasMoreLeads,
    intersectionObserverRef: lastLeadDataElement,
  } = useLeadList(debouncedSearchLead, {
    shouldFetch: open || Boolean(debouncedSearchLead),
    accessFilterId: accessFilter?.id,
  });
  const { agents, intersectionObserverRef: lastAgentDataElement } = useAgentList(debouncedSearchAgent, {
    shouldFetch: open || Boolean(debouncedSearchAgent),
    accessFilterId: accessFilter?.id,
  });

  // Reset form on toggle
  useEffect(() => {
    return function cleanupCreateCallbackModal() {
      reset();
      setProgress(Progress.SelectLead);
      setSearch(searchDefault);
    };
  }, [open]);

  // Fetch updated public holiday on lead selection
  useEffect(() => {
    (async () => {
      if (watchLead) {
        let dh: DiallingHours | undefined;
        let ph: PublicHoliday[];

        try {
          [dh, ph] = await Promise.all([
            await fetchDiallingHoursOrUndefined(watchLead.campaignId),
            await fetchPublicHolidaysOrEmptyArray(watchLead.timezone),
          ]);
        } catch (e) {
          return;
        }

        setDiallingHours(dh);
        setPublicHolidays(ph);
      }
    })();
  }, [watchLead]);

  // Reset endpoint default on endpoint type change
  useEffect(() => {
    setValue('endpoint', null);
  }, [watchLead, watchEndpointType]);

  const disableDates = (date: DateTime | null) => {
    if (diallingHours !== undefined) {
      // Disable public holidays if not allowed
      if (diallingHours.allowPublicHolidays === false) {
        if (publicHolidays.length === 0) return false;

        for (const ph of publicHolidays) {
          if (date && ph.year === date.year && ph.month === date.month && ph.day === date.day) {
            return true;
          }
        }
      }

      // Disable specific day of the week if no dialling day hours exist
      for (const day in diallingHours?.diallingDays) {
        if (diallingHours?.diallingDays[day] === null && dayToWeekdayNumber[day] === date?.weekday) {
          return true;
        }
      }
    }

    return false;
  };

  const invalidTimeSelection = (value: string): string | undefined => {
    if (value === null || value === undefined) {
      return undefined;
    }

    // If this is undefined we assume everything is fair game (even if an error occurs fetching this :/)
    if (diallingHours === undefined) {
      return undefined;
    }

    const date = DateTime.fromISO(value as string);
    const diallingDay = diallingHours.diallingDays[weekdayNumberToDay[date.weekday]];

    // As this value is null the day should already be disabled within the disableDates function.
    // If for any reason that function bugs out, this logic here should serve as a secondary
    // stop gap to prevent selecting this day/ time
    if (diallingDay === null) {
      return undefined;
    }

    // If select time is between the hours blocks we allow anything and say its valid
    if (date.hour > diallingDay.startTimeHour && date.hour < diallingDay.endTimeHour) {
      return undefined;
    }

    // Start and end edge cases
    if (
      (date.hour === diallingDay.startTimeHour && date.minute >= diallingDay.startTimeMin) ||
      (date.hour === diallingDay.endTimeHour && date.minute < diallingDay.endTimeMin)
    ) {
      return undefined;
    }

    const startHour = diallingDay.startTimeHour < 10 ? `0${diallingDay.startTimeHour}` : diallingDay.startTimeHour;
    const endHour = diallingDay.endTimeHour < 10 ? `0${diallingDay.endTimeHour}` : diallingDay.endTimeHour;
    const startMinute = diallingDay.startTimeMin < 10 ? `0${diallingDay.startTimeMin}` : diallingDay.startTimeMin;
    const endMinute = diallingDay.endTimeMin < 10 ? `0${diallingDay.endTimeMin}` : diallingDay.endTimeMin;
    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    return `Callback schedule time must be between ${startTime} and ${endTime}`;
  };

  const onSearchChange = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSearch((prev) => ({ ...prev, [key]: e.target.value }));
    },
    [],
  );

  const onSearchBlur = useCallback(
    (key: string) => (e: FocusEvent<HTMLInputElement>) => {
      setSearch((prev) => ({ ...prev, [key]: '' }));
    },
    [],
  );

  const selectLead = (lead: Lead) => {
    if (!blockCreationList.includes(lead.leadStatus)) {
      setValue('lead', lead);
      return;
    }

    pushNotification(
      'error',
      `Cannot create a callback for this lead as its in the following state ${lead.leadStatus}`,
    );
  };

  const clearLead = () => {
    setValue('lead', null);

    if (progress === Progress.SelectCallbackDetails) {
      setProgress(Progress.SelectLead);
    }
  };

  const onSubmit = handleSubmit(async (data: Form) => {
    // data.lead should not be null at this point as it is marked as required on the form schema
    const leadId = data.lead!.id;
    const timezone = data.lead!.timezone;
    const username = data.forAgent !== null && data.forAgent !== undefined ? data.forAgent.username : null;
    // Should not be null at this point as it is marked as required on the form schema
    const endpoint = data.endpoint!;

    try {
      await onAccept({
        leadId: leadId,
        forAgent: username,
        scheduled: DateTime.fromISO(data.scheduled, { zone: timezone }).toISO(),
        endpoint: endpoint,
        notes: data.notes,
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const endpointTypeListDisplay = endpointTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const scheduledHelpTextDefault =
    watchLead !== null && watchLead !== undefined ? `(${watchLead.name}'s time in ${watchLead.timezone})` : undefined;
  const scheduledHelperText = errors.scheduled ? errors.scheduled?.message : scheduledHelpTextDefault;
  const forAgentHelperText =
    '(Note: If an agent is not selected the callback will be assigned to the group of agents currently assigned to the campaign the lead is a part of. If there are no agents servicing the campaign, this callback will never be dialled.)';

  const displayList = leads.map((item, index) => (
    <ListItem key={item.id} ref={index === leads.length - 1 ? lastLeadDataElement : null} disablePadding>
      <ListItemButton onClick={() => selectLead(item)} dense>
        <ListItemText
          primary={item.name}
          secondary={
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
              <Chip
                sx={{
                  marginTop: 0.5,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                  backgroundColor: leadStatusColor[item.leadStatus]?.baseColor || grey['600'],
                }}
                label={item.leadStatus}
              />

              <Chip
                sx={{
                  marginTop: 0.5,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                }}
                color='primary'
                label={`${item.campaignName} - ${item.listName}`}
              />
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Callback'
      disableContentPadding
      content={
        <Box sx={{ padding: watchLead ? '16px 24px' : '16px 24px 0 24px' }}>
          <Stepper sx={{ marginBottom: 2 }} activeStep={progress} alternativeLabel>
            {steps.map((step) => (
              <Step
                sx={{
                  '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                    marginTop: 1,
                  },
                }}
                key={step.value}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {progress === Progress.SelectLead && (
            <Grid container spacing={2}>
              {watchLead && (
                <Grid item xs={12}>
                  <SelectedLead lead={watchLead} onClear={clearLead} />
                </Grid>
              )}

              {!watchLead && (
                <Grid item xs={12}>
                  <Box>
                    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label='Search'
                        id='search'
                        name='search'
                        defaultValue={search.lead}
                        onChange={onSearchChange('lead')}
                      />
                    </Box>

                    <Divider variant='fullWidth' component='hr' />

                    <Box sx={{ height: 400, overflow: 'auto' }}>
                      <AsyncLoader isLoading={fetchingLeads && leads.length === 0}>
                        {displayList.length > 0 && (
                          <>
                            <List sx={{ paddingTop: 0, paddingBottom: 0 }}>{displayList}</List>
                            {fetchingLeads && displayList.length > 0 && <DotLoader align='center' />}

                            {!fetchingLeads && !hasMoreLeads && (
                              <Typography
                                sx={{ marginTop: 1, marginBottom: 1 }}
                                variant='body2'
                                align='center'
                                color='textSecondary'>
                                No more results to display
                              </Typography>
                            )}

                            {leadsFetchError && displayList.length > 0 && (
                              <Typography
                                sx={{ marginTop: 1, marginBottom: 1 }}
                                variant='body2'
                                align='center'
                                color='textSecondary'>
                                Failed to load campaigns
                              </Typography>
                            )}
                          </>
                        )}

                        {displayList.length === 0 && debouncedSearchLead !== '' && (
                          <Typography
                            sx={{ marginTop: 1, marginBottom: 1 }}
                            variant='body2'
                            align='center'
                            color='textSecondary'>
                            No campaigns found matching your search criteria.
                          </Typography>
                        )}

                        {displayList.length === 0 && leads.length === 0 && debouncedSearchLead === '' && (
                          <Typography
                            sx={{ marginTop: 1, marginBottom: 1 }}
                            variant='body2'
                            align='center'
                            color='textSecondary'>
                            No campaigns currently exist
                          </Typography>
                        )}

                        {displayList.length === 0 && leads.length > 0 && debouncedSearchLead === '' && (
                          <Typography
                            sx={{ marginTop: 1, marginBottom: 1 }}
                            variant='body2'
                            align='center'
                            color='textSecondary'>
                            No more campaigns to choose from.
                          </Typography>
                        )}
                      </AsyncLoader>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}

          {progress === Progress.SelectCallbackDetails && (
            <Grid container spacing={2}>
              {watchLead && (
                <>
                  <Grid sx={{ marginBottom: 2 }} item xs={12}>
                    <SelectedLead lead={watchLead} onClear={clearLead} />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='endpointType'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          select
                          variant='outlined'
                          label='Endpoint Type'
                          disabled={isLoading}
                          required={true}
                          error={Boolean(errors.endpointType)}
                          helperText={errors.endpointType?.message}>
                          {endpointTypeListDisplay}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='endpoint'
                      control={control}
                      rules={{
                        required: 'Endpoint is required.',
                      }}
                      render={({ field }) => (
                        <>
                          {watchEndpointType === EndpointType.Existing && (
                            <Autocomplete
                              {...field}
                              onChange={(e, data) => field.onChange(data)}
                              fullWidth
                              options={endpointsList}
                              filterSelectedOptions
                              disabled={isLoading}
                              renderInput={(params) => (
                                <TextField
                                  label='Endpoint'
                                  required={true}
                                  error={Boolean(errors.endpoint)}
                                  helperText={errors.endpoint?.message}
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          )}

                          {watchEndpointType === EndpointType.New && (
                            <TextField
                              {...field}
                              value={field.value || ''}
                              fullWidth
                              variant='outlined'
                              label='Endpoint'
                              disabled={isLoading}
                              required={true}
                              error={Boolean(errors.endpoint)}
                              helperText={errors.endpoint?.message}
                            />
                          )}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='scheduled'
                      control={control}
                      rules={{
                        required: 'A callback date/ time is required',
                        validate: invalidTimeSelection,
                      }}
                      render={({ field }) => (
                        <DateTimePicker
                          disableMaskedInput
                          label='Callback Schedule'
                          inputFormat='dd/MM/yyyy hh:mm a'
                          disabled={isLoading}
                          disablePast={true}
                          shouldDisableDate={disableDates}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant='outlined'
                              required={true}
                              error={Boolean(errors.scheduled)}
                              helperText={scheduledHelperText}
                            />
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Controller
                  name='forAgent'
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(e, data) => field.onChange(data)}
                      fullWidth
                      options={agents}
                      filterSelectedOptions
                      disabled={isLoading}
                      getOptionLabel={(option) => option?.fullName || ''}
                      renderOption={(props, option) => (
                        <li {...props} ref={lastAgentDataElement} key={option.username}>
                          <div>
                            <Typography variant='body2' color='textPrimary' component='p'>
                              {option.fullName}
                            </Typography>

                            <Typography variant='caption' color='textSecondary' component='p'>
                              {option.username}
                            </Typography>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='For Agent'
                          variant='outlined'
                          onBlur={onSearchBlur('agent')}
                          onChange={onSearchChange('agent')}
                          helperText={forAgentHelperText}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='notes'
                  control={control}
                  rules={{
                    required: 'Notes is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      variant='outlined'
                      label='Notes'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(errors.notes)}
                      helperText={errors.notes?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <BackButton onClick={() => setProgress(Progress.SelectLead)} disabled={progress === Progress.SelectLead}>
            Back
          </BackButton>

          {progress === Progress.SelectLead && (
            <Button
              variant='contained'
              disableElevation
              color='primary'
              disabled={!watchLead}
              onClick={() => setProgress(Progress.SelectCallbackDetails)}>
              Next
            </Button>
          )}

          {progress === Progress.SelectCallbackDetails && (
            <LoadingButton
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={isLoading}
              loading={isLoading}>
              Create
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default CreateCallbackModal;
