import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getCampaignById, getRoutingProfiles } from '../api';
import { Campaign, RoutingProfile } from '../domain';
import CampaignConfiguration from './CampaignConfiguration';
import CampaignDispositions from './CampaignDispositions';
import CampaignFilters from './CampaignFilters';
import CampaignLeadLists from './CampaignLeadLists';
import { CampaignSummary, CampaignSummarySuperset } from './CampaignSummary';

// Used to manage visible tab selection
enum ViewTabsType {
  Summary,
  LeadLists,
  Dispositions,
  Filters,
  Settings,
}

interface Error {
  text: string;
  subText: string;
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

// default tab, fallback to this tab when no permission on specified tab
const defaultViewTab = ViewTabsType.Summary;

const CampaignDetails = () => {
  const { policies } = useAuth();
  const appConfig = useAppConfiguration();
  const { campaignId } = useParams() as { campaignId: string };
  const navigate = useNavigate();

  // Map for restricted tabs(key: ViewTabsType, value: boolean(true means can access))
  const restrictedViewTabs = useMemo(
    () =>
      new Map([
        [
          ViewTabsType.LeadLists,
          isAllowedRole([PolicyType.TeamLeader, PolicyType.Manager, PolicyType.DiallerAdmin], policies),
        ],
        [ViewTabsType.Dispositions, isAllowedRole([PolicyType.DiallerAdmin], policies)],
        [ViewTabsType.Filters, isAllowedRole([PolicyType.DiallerAdmin], policies)],
        [ViewTabsType.Settings, isAllowedRole([PolicyType.DiallerAdmin], policies)],
      ]),
    [policies],
  );

  // checks whether you have permission on specified ViewTabsType
  const hasViewTabPermission = useCallback(
    (viewTabsType: ViewTabsType) => {
      const allowed = restrictedViewTabs.get(viewTabsType);
      return allowed === undefined || allowed;
    },
    [restrictedViewTabs],
  );

  const setPageTitleProps = useSetPageTitleProps();
  // Should always be false after initial load, subsequent data fetches should not trigger the async loader
  // else issues around child unmounting when triggering a parent re-render will occur
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const [routingProfiles, setRoutingProfiles] = useState<RoutingProfile[]>([]);
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];
  const supersetUrl = appConfig.web.supersetUrl;

  // Make fresh update on tab change to make sure we have the latest data on navigation change
  useEffect(() => {
    // fallback to default one when no permission(can happen when show parameter in the URL is manually updated)
    if (!hasViewTabPermission(tabIndex)) {
      setSearchParams({
        show: ViewTabsType[defaultViewTab],
      });
    }

    (async () => {
      if (searchParams.get('show')) {
        let campaign;
        let routingProfileList;

        try {
          [campaign, routingProfileList] = await Promise.all([getCampaignById(+campaignId), getRoutingProfiles()]);
        } catch (e) {
          handleError(e);
          return;
        } finally {
          setInitialLoad(false);
        }

        setCampaign(campaign);
        setRoutingProfiles(routingProfileList);
        // Set page title
        setPageTitleProps({ pageName: campaign?.name });
      }
    })();
  }, [searchParams.get('show')]);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const fetchCampaign = async (): Promise<void> => {
    let fetchedCampaign: Campaign | undefined;

    try {
      fetchedCampaign = await getCampaignById(+campaignId);
    } catch (e) {
      handleError(e);
      return;
    }

    setCampaign(fetchedCampaign);
    // Set page title
    setPageTitleProps({ pageName: fetchedCampaign?.name });
  };

  const errorAction = () => {
    navigate(Routes.diallerConfig.path);
  };

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error.text}
      subText={`${error.subText} \n Click the button below to return to dialler configuration.`}
      action={errorAction}
      actionText='Dialler Config'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.diallerConfig.path}>
          Dialler Configuration
        </Link>
        <Typography color='textPrimary'>Campaign Details</Typography>
      </Breadcrumbs>

      {campaign === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to the campaign list.'
          action={errorAction}
          actionText='Campaign List'
        />
      )}

      {campaign !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {campaign?.name}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='Vertical tabs example'>
            <Tab label='Summary' {...a11yProps(ViewTabsType.Summary)} value={ViewTabsType.Summary} />

            {hasViewTabPermission(ViewTabsType.LeadLists) && (
              <Tab label='Lead Lists' {...a11yProps(ViewTabsType.LeadLists)} value={ViewTabsType.LeadLists} />
            )}

            {hasViewTabPermission(ViewTabsType.Dispositions) && (
              <Tab label='Dispositions' {...a11yProps(ViewTabsType.Dispositions)} value={ViewTabsType.Dispositions} />
            )}

            {hasViewTabPermission(ViewTabsType.Filters) && campaign.leadEngineSettings && (
              <Tab label='Filters' {...a11yProps(ViewTabsType.Filters)} value={ViewTabsType.Filters} />
            )}

            {hasViewTabPermission(ViewTabsType.Settings) && (
              <Tab label='Settings' {...a11yProps(ViewTabsType.Settings)} value={ViewTabsType.Settings} />
            )}
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Summary}>
            {supersetUrl != '' ? (
              <CampaignSummarySuperset campaign={campaign} supersetUrl={supersetUrl} />
            ) : (
              <CampaignSummary campaign={campaign} supersetUrl={supersetUrl} />
            )}
          </TabPanel>

          {hasViewTabPermission(ViewTabsType.LeadLists) && (
            <TabPanel value={tabIndex} index={ViewTabsType.LeadLists}>
              <CampaignLeadLists list={campaign?.lists} triggerCampaignRefresh={fetchCampaign} />
            </TabPanel>
          )}

          {hasViewTabPermission(ViewTabsType.Dispositions) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Dispositions}>
              <CampaignDispositions
                dispositions={campaign.dispositions}
                diallerType={campaign.diallerType}
                campaignType={campaign.campaignType}
                triggerCampaignRefresh={fetchCampaign}
              />
            </TabPanel>
          )}

          {hasViewTabPermission(ViewTabsType.Filters) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Filters}>
              <CampaignFilters campaign={campaign} triggerCampaignRefresh={fetchCampaign} />
            </TabPanel>
          )}

          {hasViewTabPermission(ViewTabsType.Settings) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Settings}>
              <CampaignConfiguration
                campaign={campaign}
                triggerCampaignRefresh={fetchCampaign}
                routingProfiles={routingProfiles}
              />
            </TabPanel>
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default CampaignDetails;
