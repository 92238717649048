import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, List, Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import blueGrey from '@mui/material/colors/blueGrey';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import FilterDrawer from '~pages/Callbacks/FilterDrawer';
import useCallbacks from '~pages/Callbacks/useCallbacks';
import {
  CallbackFilterValues,
  CreateCallback,
  FilterOptions,
  SortColumnValues,
  SortOrder,
} from '~pages/Callbacks/useCallbacks/domain';
import { UpdateCallback } from '~pages/CampaignManagement/domain';
import { PolicyType, isAllowedRole, useAuth } from '~providers/AuthProvider';
import { useNotification } from '~providers/NotificationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { parseBoolean } from '~utils/Functions';

import CallbackCard from './CallbackCard';
import CallbackDetailsModal from './CallbackDetailsModal';
import CreateCallbackModal from './CreateCallbackModal';
import EditCallbackModal from './EditCallbackModal';

type FilterButtonProps = MUIStyledCommonProps<Theme> &
  ButtonProps & {
    isActive: boolean;
  };

const FilterButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isActive' })<FilterButtonProps>(
  ({ theme, isActive }) => ({
    'color': isActive ? theme.palette.primary.contrastText : theme.palette.getContrastText(blueGrey[900]),
    'backgroundColor': isActive ? theme.palette.primary.main : blueGrey[900],
    ':hover': {
      backgroundColor: isActive ? theme.palette.primary.dark : blueGrey[800],
    },
  }),
);

const numberArrayOrEmptyList = (value: string[] | null): number[] => {
  if (!value) {
    return [];
  }

  let numbers: number[] = [];
  for (let num of value) {
    const number = Number(num);

    // Assume bad data and reject
    if (Number.isNaN(number)) {
      return [];
    }

    numbers = [...numbers, number];
  }

  return numbers;
};

// Note: This excludes access filters id as it does not really fit into the view display for user selected filters
const hasFilterSelections = (filter: FilterOptions) => {
  let flag = false;

  if (filter.search) {
    flag = true;
  }

  if (filter.filter) {
    flag = true;
  }

  if (filter.listId && filter.listId.length > 0) {
    flag = true;
  }

  if (filter.campaignId && filter.campaignId.length > 0) {
    flag = true;
  }

  if (filter.agentUsername && filter.agentUsername.length > 0) {
    flag = true;
  }

  if (filter.assignedToGroup) {
    flag = true;
  }

  if (filter.dateFrom) {
    flag = true;
  }

  if (filter.dateTo) {
    flag = true;
  }

  if (filter.sortColumn) {
    flag = true;
  }

  if (filter.sortOrder) {
    flag = true;
  }

  return flag;
};

const URLSearchParamsToQuery = (searchParams: URLSearchParams): FilterOptions => {
  const assignedToGroup = searchParams.get('assignedToGroup');

  return {
    search: searchParams.get('search') || undefined,
    filter: (searchParams.get('filter') as CallbackFilterValues) || undefined,
    listId: numberArrayOrEmptyList(searchParams.getAll('listId')),
    campaignId: numberArrayOrEmptyList(searchParams.getAll('campaignId')),
    agentUsername: searchParams.getAll('agentUsername'),
    dateFrom: searchParams.get('dateFrom') || undefined,
    dateTo: searchParams.get('dateTo') || undefined,
    sortColumn: (searchParams.get('sortColumn') as SortColumnValues) || undefined,
    sortOrder: (searchParams.get('sortOrder') as SortOrder) || undefined,
    assignedToGroup: assignedToGroup !== null ? parseBoolean(assignedToGroup) : undefined,
  };
};

function Callbacks() {
  const { policies } = useAuth();
  const { pushNotification } = useNotification();
  const { accessFilter } = useUserPreferences();
  const accessFilterId = useMemo(() => accessFilter?.id, [accessFilter]);
  // note default sets access filter id as it is not managed on this level of the application and must always be set
  const filterDefault: FilterOptions = Object.freeze({
    filter: undefined,
    listId: [],
    campaignId: [],
    agentUsername: [],
    dateFrom: undefined,
    dateTo: undefined,
    assignedToGroup: undefined,
  });
  const [searchParams, setSearchParams] = useSearchParams(queryString.stringify(filterDefault));
  const [filter, setFilter] = useState<FilterOptions>(URLSearchParamsToQuery(searchParams));
  const callbackSearchOptions = useMemo(() => URLSearchParamsToQuery(searchParams), [searchParams]);
  const [selectedRef, setSelectedRef] = useState<number | undefined>(undefined);
  const [editableRef, setEditableRef] = useState<number | undefined>(undefined);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [submittingData, setSubmittingData] = useState(false);
  const {
    list: callbacks,
    intersectionObserverRef: lastCallbackElementRef,
    loading: callbackFetching,
    error: callbackFetchError,
    hasMore: hasMoreCallbacks,
    create: createCallback,
    update: updateCallback,
    remove: removeCallback,
    downloadLink: callbacksDownloadLink,
  } = useCallbacks({ ...callbackSearchOptions, accessFilterId });
  const selectedCallback = useMemo(() => callbacks.find((item) => item.id === selectedRef), [selectedRef]);
  const editableCallback = useMemo(() => callbacks.find((item) => item.id === editableRef), [editableRef]);
  const canDownload = isAllowedRole([PolicyType.Manager, PolicyType.QualityAnalyst, PolicyType.DiallerAdmin], policies);
  const filtersEnabled = hasFilterSelections(callbackSearchOptions);

  const toggleCreateCallbackModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onCallbackCreate = async (data: CreateCallback) => {
    setSubmittingData(true);
    try {
      await createCallback(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully create a callback.');
    setCreateModalOpen(false);
  };

  const onCallbackUpdate = async (callbackId: number, data: UpdateCallback) => {
    setSubmittingData(true);

    try {
      await updateCallback(callbackId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully updated the callback.');
    setEditableRef(undefined);
  };

  const onCallbackRemove = (callbackId: number) => async () => {
    try {
      await removeCallback(callbackId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', 'You have successfully removed the callback.');
  };

  const onUpdate =
    <T extends keyof FilterOptions>(key: keyof FilterOptions) =>
    (value: FilterOptions[T]) => {
      setFilter((prev) => ({ ...prev, [key]: value }));
    };

  const toggleFilterBar = () => {
    setShowFilters((prev) => !prev);
  };

  const onSearch = useCallback(() => {
    setSearchParams(queryString.stringify(filter));
    setShowFilters(false);
  }, [filter]);

  const resetFilter = () => {
    setSearchParams(queryString.stringify(filterDefault));
    setFilter(filterDefault);
    setShowFilters(false);
  };

  const displayList = useMemo(
    () =>
      callbacks.map((item, index) => (
        <CallbackCard
          ref={index === callbacks.length - 1 ? lastCallbackElementRef : undefined}
          key={item.id}
          callback={item}
          onClick={() => {
            setSelectedRef(item.id);
          }}
          onEdit={() => {
            setEditableRef(item.id);
          }}
          onRemove={onCallbackRemove(item.id)}
        />
      )),
    [callbacks, lastCallbackElementRef],
  );

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Callbacks
      </Typography>

      <Grid sx={{ marginBottom: 3 }} container spacing={1}>
        <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <FilterButton
            fullWidth
            disableElevation
            variant='contained'
            isActive={filtersEnabled}
            startIcon={<FilterListIcon />}
            onClick={toggleFilterBar}
            title='Filter'>
            {showFilters ? 'Hide' : 'Show'} Filters
          </FilterButton>
        </Grid>

        {!canDownload && (
          <Hidden mdDown>
            <Grid item md={6}></Grid>
          </Hidden>
        )}

        {canDownload && (
          <>
            <Hidden mdDown>
              <Grid item md={3}></Grid>
            </Hidden>

            <Grid sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} item xs={12} md={3}>
              <Button
                disabled={!canDownload || Boolean(callbackFetchError)}
                fullWidth
                disableElevation
                variant='contained'
                href={callbacksDownloadLink}
                startIcon={<CloudDownloadIcon />}
                color='primary'
                title='Download'>
                Download
              </Button>
            </Grid>
          </>
        )}

        <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={toggleCreateCallbackModal}>
            Create Callback
          </Button>
        </Grid>
      </Grid>

      <FilterDrawer open={showFilters} filter={filter} onReset={resetFilter} onSearch={onSearch} onUpdate={onUpdate} />

      <AsyncLoader isLoading={callbackFetching && callbacks.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {displayList.length > 0 && (
              <>
                <List>{displayList}</List>
                {callbackFetching && <DotLoader align='center' />}

                {!callbackFetching && !hasMoreCallbacks && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {!callbackFetching && callbackFetchError && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load callbacks
                  </Typography>
                )}
              </>
            )}

            {!callbackFetching && callbacks.length === 0 && filtersEnabled && (
              <EmptyState
                type='no-records-found'
                text='No callbacks found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {!callbackFetching && callbacks.length === 0 && !filtersEnabled && (
              <EmptyState type='no-items-3' text='No callbacks currently exist' />
            )}
          </Grid>
        </Grid>

        <CallbackDetailsModal
          open={Boolean(selectedCallback)}
          callback={selectedCallback}
          onClose={() => {
            setSelectedRef(undefined);
          }}
        />

        <CreateCallbackModal
          open={createModalOpen}
          submitting={submittingData}
          onAccept={onCallbackCreate}
          onClose={toggleCreateCallbackModal}
        />

        <EditCallbackModal
          open={Boolean(editableCallback)}
          submitting={submittingData}
          callback={editableCallback}
          onAccept={onCallbackUpdate}
          onClose={() => {
            setEditableRef(undefined);
          }}
        />
      </AsyncLoader>
    </>
  );
}

export default Callbacks;
