import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AsyncQueue } from '~pages/AsyncManagement/domain';

import { getAsyncQueues, getAsyncQueuesByNextUrl } from './api';
import { AsyncQueueResponse } from './domain';

type Options = {
  accessFilterId?: number;
  shouldFetch?: boolean;
};

const useAsyncQueueSearch = (search: string, options: Options = { accessFilterId: undefined, shouldFetch: true }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<AsyncQueue[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const accessFilterId = options.accessFilterId;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: AsyncQueueResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAsyncQueuesByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.queues]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: AsyncQueueResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAsyncQueues(search, accessFilterId, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.queues);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search, accessFilterId]);

  useEffect(() => {
    setList([]);
  }, [search, accessFilterId, shouldFetch]);

  useEffect(() => {
    const load = async (search: string, accessFilterId: number | undefined) => {
      setLoading(true);
      setError(false);

      let resp: AsyncQueueResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAsyncQueues(search, accessFilterId, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.queues]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search, accessFilterId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, accessFilterId, shouldFetch]);

  return { loading, error, list, hasMore, getNextPage, reload };
};

export default useAsyncQueueSearch;
