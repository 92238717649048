import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import useConnectStats from '~hooks/useConnectStats';
import useDebounce from '~hooks/useDebounce';
import {
  CampaignType,
  CreateDiallerGroup,
  DiallerType,
  PushPreviewSeconds,
  RingOutSeconds,
} from '~pages/CampaignManagement/domain';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

interface Props {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateDiallerGroup) => void;
  onClose: () => void;
}

interface ListItem {
  id: number;
  name: string;
}

interface Form {
  name: string;
  description: string;
  diallerType: DiallerType | null;
  campaignType: CampaignType | null;
  enableManualOutboundWhenAvailable: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  systemDisposedInboundCalls: boolean;
  enableVoicemailMessage: false;
  voicemailMessageARN: string;
  // Preview Settings - use if campaignType is preview
  enableEndpointSelection: boolean;
  allowPushPreviewSeconds: boolean;
  pushPreviewSeconds: number;
  allowRingOutSeconds: boolean;
  ringOutSeconds: number;
  // Predictive Settings - use if campaignType is predictive
  ringTimeSeconds: number;
  holdDropSeconds: number;
  maxLinesPerAgent: number;
  lookaheadTimeSeconds: number;
  numReservedAgents: number;
  outboundQueue: string;
  // Extra
  accessFilters: ListItem[];
}

const diallerTypeList = [
  {
    label: 'Connect',
    value: DiallerType.Connect,
  },
  {
    label: 'SIP',
    value: DiallerType.SIP,
  },
];

const asteriskCampaignTypeList = [
  {
    label: 'Predictive',
    value: CampaignType.Predictive,
  },
];

const connectCampaignTypeList = [
  {
    label: 'Preview',
    value: CampaignType.Preview,
  },
  {
    label: 'Predictive',
    value: CampaignType.Predictive,
  },
];

const CreateDiallerGroupModal = ({ open, submitting, onAccept, onClose }: Props) => {
  const { accessFilter } = useUserPreferences();
  const [searchAccessFilter, setSearchAccessFilter] = useState<string>('');
  const debouncedSearchAccessFilter = useDebounce(searchAccessFilter, 500);
  const {
    loading: accessFilterFetching,
    error: accessFilterFetchError,
    list: accessFilters,
    hasMore: hasMoreAccessFilters,
    getNextPage: getNextAccessFiltersPage,
  } = useAccessFilterSearch(debouncedSearchAccessFilter, { archived: false, shouldFetch: open });
  const { loading: connectQueueStatsFetching, queueStats } = useConnectStats({ shouldFetch: open });
  const isLoading = connectQueueStatsFetching || accessFilterFetching || submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      diallerType: null,
      campaignType: null,
      enableManualOutboundWhenAvailable: false,
      manualOutboundRequireDetails: false,
      enablePreparedOutboundHangup: false,
      enableManualOutboundWhenOffline: false,
      systemDisposedInboundCalls: false,
      enableVoicemailMessage: false,
      voicemailMessageARN: '',
      enableEndpointSelection: false,
      allowPushPreviewSeconds: false,
      pushPreviewSeconds: PushPreviewSeconds.Min,
      allowRingOutSeconds: false,
      ringOutSeconds: RingOutSeconds.Min,
      ringTimeSeconds: 30,
      holdDropSeconds: 5,
      maxLinesPerAgent: 1.4,
      lookaheadTimeSeconds: 30,
      numReservedAgents: 0,
      outboundQueue: '',
      accessFilters: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const accessFiltersWatch = watch('accessFilters');
  const diallerTypeWatch = watch('diallerType');
  const campaignTypeWatch = watch('campaignType');
  const enableManualOutboundWhenAvailableWatch = watch('enableManualOutboundWhenAvailable');
  const enableManualOutboundWhenOfflineWatch = watch('enableManualOutboundWhenOffline');
  const showManualOutboundOptions = enableManualOutboundWhenAvailableWatch || enableManualOutboundWhenOfflineWatch;
  const enableVoicemailMessageWatch = watch('enableVoicemailMessage');
  const watchAllowPushPreviewSeconds = watch('allowPushPreviewSeconds');
  const watchAllowRingOutSeconds = watch('allowRingOutSeconds');
  const isConnectPredictive = diallerTypeWatch === DiallerType.Connect && campaignTypeWatch === CampaignType.Predictive;
  const campaignTypeList = useMemo<{ label: string; value: string }[]>(() => {
    switch (diallerTypeWatch) {
      case DiallerType.SIP: {
        return asteriskCampaignTypeList;
      }
      case DiallerType.Connect: {
        return connectCampaignTypeList;
      }
      default: {
        return [];
      }
    }
  }, [diallerTypeWatch]);
  const accessFiltersObserver = useRef<IntersectionObserver | undefined>(undefined);
  const lastAccessFilterDataElement = useCallback(
    (node: any) => {
      if (accessFilterFetching) return;
      if (accessFiltersObserver.current) accessFiltersObserver.current.disconnect();
      accessFiltersObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreAccessFilters) {
          getNextAccessFiltersPage();
        }
      });
      if (node) accessFiltersObserver.current.observe(node);
    },
    [accessFilterFetching, hasMoreAccessFilters, getNextAccessFiltersPage],
  );

  useEffect(() => {
    if (open) {
      if (accessFilter) {
        setValue('accessFilters', [{ id: accessFilter.id, name: accessFilter.name }]);
      }
    }

    return () => {
      reset();
    };
  }, [open]);

  // Clears campaign type if dialler type changes
  useEffect(() => {
    setValue('campaignType', null);
  }, [diallerTypeWatch]);

  // Update push preview seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates infinite preview time
  useEffect(() => {
    if (watchAllowPushPreviewSeconds === true) {
      setValue('pushPreviewSeconds', PushPreviewSeconds.Min);
    } else {
      setValue('pushPreviewSeconds', PushPreviewSeconds.Infinite);
    }
  }, [watchAllowPushPreviewSeconds]);

  // Update ring out seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates that we do not auto ring out and leave it up to the contact service provider
  useEffect(() => {
    if (watchAllowRingOutSeconds === true) {
      setValue('ringOutSeconds', RingOutSeconds.Min);
    } else {
      setValue('ringOutSeconds', RingOutSeconds.Infinite);
    }
  }, [watchAllowRingOutSeconds]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: CreateDiallerGroup = {
      name: data.name,
      description: data.description || '',
      diallerType: data.diallerType as DiallerType,
      campaignType: data.campaignType as CampaignType,
      enableManualOutbound: data.enableManualOutboundWhenAvailable,
      manualOutboundRequireDetails: data.manualOutboundRequireDetails,
      enablePreparedOutboundHangup: data.enablePreparedOutboundHangup,
      enableManualOutboundWhenOffline: data.enableManualOutboundWhenOffline,
      systemDisposedInboundCalls: data.systemDisposedInboundCalls,
      previewSettings: undefined,
      predictiveSettings: undefined,
      accessFilterIds: data.accessFilters.length > 0 ? data.accessFilters.map((item) => item.id) : undefined,
    };

    if (data.enableVoicemailMessage) {
      submitData = {
        ...submitData,
        voicemailMessageARN: data.voicemailMessageARN,
      };
    }

    if (data.campaignType === CampaignType.Preview) {
      submitData = {
        ...submitData,
        previewSettings: {
          enableEndpointSelection: data.enableEndpointSelection,
          pushPreviewSeconds: data.pushPreviewSeconds,
          ringOutSeconds: data.ringOutSeconds,
        },
      };
    } else {
      submitData = {
        ...submitData,
        predictiveSettings: {
          ringTimeSeconds: data.ringTimeSeconds,
          holdDropSeconds: data.holdDropSeconds,
          maxLinesPerAgent: data.maxLinesPerAgent,
          lookaheadTimeSeconds: data.lookaheadTimeSeconds,
          numReservedAgents: data.numReservedAgents,
          outboundQueue: data.outboundQueue || undefined,
        },
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const diallerTypeListDisplay = diallerTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const campaignTypeListDisplay = campaignTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchAccessFilter(e.target.value);
  }, []);

  const onSearchBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setSearchAccessFilter('');
  }, []);

  const accessFiltersNoOptionsText = useMemo(() => {
    if (accessFilterFetching) {
      return <DotLoader align='center' />;
    }

    if (accessFilterFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load access filters
        </Typography>
      );
    }

    return undefined;
  }, [accessFilterFetching, accessFilterFetchError]);

  const accessFiltersFilteredList: ListItem[] = useMemo(() => {
    let list: ListItem[] = accessFilters.map((item) => ({ id: item.id, name: item.name }));
    if (accessFilter) {
      list = [...list, { id: accessFilter.id, name: accessFilter.name }];
    }
    if (!accessFiltersWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return !accessFiltersWatch.find((watchItem) => listItem.id === watchItem.id);
    });
  }, [accessFilters, accessFiltersWatch]);

  const queueIdList = Object.keys(queueStats).sort((a, b) => {
    let an = queueStats[a]?.name;
    let bn = queueStats[b]?.name;
    return an == bn ? 0 : an > bn ? 1 : -1;
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Dialler Group'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{}}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='diallerType'
              control={control}
              rules={{
                required: 'Dialler type is required.',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  variant='outlined'
                  label='Dialler Type'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.diallerType)}
                  helperText={errors.diallerType?.message}
                  value={field.value || ''}>
                  {diallerTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='campaignType'
              control={control}
              rules={{
                required: 'Campaign type is required.',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  variant='outlined'
                  label='Campaign Type'
                  disabled={isLoading || campaignTypeListDisplay.length === 0}
                  required={true}
                  error={Boolean(errors.campaignType)}
                  helperText={errors.campaignType?.message}
                  value={field.value || ''}>
                  {campaignTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          {diallerTypeWatch && diallerTypeWatch !== DiallerType.SIP && campaignTypeWatch && (
            <>
              {campaignTypeWatch && campaignTypeWatch !== CampaignType.Predictive && (
                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='enableManualOutboundWhenAvailable'
                    label='Enable Manual Outbound When Available?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='enableManualOutboundWhenOffline'
                  label='Enable Manual Outbound When Offline?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              {showManualOutboundOptions && (
                <>
                  <Grid item xs={12}>
                    <ControlledCheckbox
                      name='manualOutboundRequireDetails'
                      label='Manual outbound require details?'
                      control={control}
                      disabled={submitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ControlledCheckbox
                      name='enablePreparedOutboundHangup'
                      label='Enable Prepared Outbound Hangup?'
                      control={control}
                      disabled={submitting}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='systemDisposedInboundCalls'
                  label='System Dispose Inbound Calls?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='enableVoicemailMessage'
                  label='Enable Voicemail Message?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              {enableVoicemailMessageWatch && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name='voicemailMessageARN'
                      control={control}
                      rules={{
                        required: 'Voicemail message ARN is required.',
                        validate: (value) => {
                          if (!value || (value && value.length === 0)) return undefined;

                          if (!value.startsWith('arn:')) {
                            return 'Voicemail message ARN must be a valid ARN.';
                          }

                          return undefined;
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant='outlined'
                          label='Voicemail Message ARN'
                          disabled={submitting}
                          required={true}
                          error={Boolean(errors.voicemailMessageARN)}
                          helperText={errors.voicemailMessageARN?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          {campaignTypeWatch === CampaignType.Preview && (
            <>
              <Grid item xs={12}>
                <ControlledCheckbox
                  name='enableEndpointSelection'
                  label='Enable Endpoint Selection?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='allowPushPreviewSeconds'
                  label='Allow Push Preview Seconds?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              {watchAllowPushPreviewSeconds && (
                <Grid item xs={12}>
                  <ControlledNumberField
                    name='pushPreviewSeconds'
                    control={control}
                    rules={{
                      required: 'push preview seconds is required.',
                      min: {
                        value: PushPreviewSeconds.Min,
                        message: `push preview seconds cannot be lower than ${PushPreviewSeconds.Min}.`,
                      },
                      max: {
                        value: PushPreviewSeconds.Max,
                        message: `push preview seconds cannot be greater than ${PushPreviewSeconds.Max}.`,
                      },
                    }}
                    label='Push Preview Seconds'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.pushPreviewSeconds)}
                    helperText={errors.pushPreviewSeconds?.message}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='allowRingOutSeconds'
                  label='Allow Ring Out Seconds?'
                  control={control}
                  disabled={submitting}
                />
              </Grid>

              {watchAllowRingOutSeconds && (
                <Grid item xs={12}>
                  <ControlledNumberField
                    name='ringOutSeconds'
                    control={control}
                    rules={{
                      required: 'ring out seconds is required.',
                      min: {
                        value: RingOutSeconds.Min,
                        message: `ring out seconds cannot be lower than ${RingOutSeconds.Min}.`,
                      },
                      max: {
                        value: RingOutSeconds.Max,
                        message: `ring out seconds cannot be greater than ${RingOutSeconds.Max}.`,
                      },
                    }}
                    label='Ring Out Seconds'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.ringOutSeconds)}
                    helperText={errors.ringOutSeconds?.message}
                  />
                </Grid>
              )}
            </>
          )}

          {campaignTypeWatch === CampaignType.Predictive && (
            <>
              <Grid item xs={12}>
                <ControlledNumberField
                  name='holdDropSeconds'
                  control={control}
                  rules={{
                    required: 'hold drop seconds is required.',
                  }}
                  label='Hold Drop Seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.holdDropSeconds)}
                  helperText={errors.holdDropSeconds?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledNumberField
                  name='maxLinesPerAgent'
                  control={control}
                  rules={{
                    required: 'max lines per agent is required.',
                  }}
                  label='Max Lines Per Agent'
                  disabled={submitting}
                  required={true}
                  isFloat={true}
                  error={Boolean(errors.maxLinesPerAgent)}
                  helperText={errors.maxLinesPerAgent?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledNumberField
                  name='lookaheadTimeSeconds'
                  control={control}
                  rules={{
                    required: 'lookahead time seconds is required.',
                  }}
                  label='Lookahead Time Seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.lookaheadTimeSeconds)}
                  helperText={errors.lookaheadTimeSeconds?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledNumberField
                  name='ringTimeSeconds'
                  control={control}
                  rules={{
                    required: 'ring time seconds is required.',
                  }}
                  label='Ring Time Seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.ringTimeSeconds)}
                  helperText={errors.ringTimeSeconds?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledNumberField
                  name='numReservedAgents'
                  control={control}
                  rules={{
                    required: 'number of reserved agents is required.',
                  }}
                  label='Number of Reserved Agents'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.numReservedAgents)}
                  helperText={errors.numReservedAgents?.message}
                />
              </Grid>

              {isConnectPredictive && (
                <Grid item xs={12}>
                  <Controller
                    name='outboundQueue'
                    control={control}
                    rules={{
                      required: 'Outbound queue is required',
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        onChange={(e, data) => {
                          field.onChange(data);
                        }}
                        options={queueIdList}
                        disabled={isLoading}
                        disableClearable
                        getOptionLabel={(option) => queueStats[option]?.name || ''}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            <Typography variant='body1' color='textPrimary' component='p'>
                              {queueStats[option]?.name}
                            </Typography>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Outbound Queue'
                            variant='outlined'
                            required={true}
                            error={Boolean(errors.outboundQueue)}
                            helperText={errors.outboundQueue?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Controller
              name='accessFilters'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  multiple
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={accessFiltersFilteredList}
                  noOptionsText={accessFiltersNoOptionsText}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastAccessFilterDataElement} key={option.id}>
                      <Typography variant='body1' color='textPrimary' component='p'>
                        {option.name}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Access Filters'
                      variant='outlined'
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateDiallerGroupModal;
