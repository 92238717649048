import LoadingButton from '@mui/lab/LoadingButton';
import { ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import SectionCard from '~components/SectionCard';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupSearch from '~pages/CampaignManagement/DiallerGroupList/useDiallerGroupSearch';
import PreferenceLegend from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences/PreferenceLegend';
import { AccessFilterDiallerGroupEntry, UpdateAccessFilter } from '~pages/SystemManagement/domain';

type Props = {
  assignedGroups: AccessFilterDiallerGroupEntry[];
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  update: (data: Partial<UpdateAccessFilter>) => Promise<void>;
};

const legend = [
  {
    label: 'Active',
    color: blue[500],
  },
  {
    label: 'Archived',
    color: grey[600],
  },
];

const DiallerGroupFilterPreferences = ({ assignedGroups, isEdit, submitting, toggleEdit, update }: Props) => {
  const [selected, setSelected] = useState<number[]>(assignedGroups.map((item) => item.id));
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const { loading, list, error, getNextPage, hasMore } = useDiallerGroupSearch(debouncedSearch, {
    shouldFetch: isEdit,
  });
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onSearchChange = useCallback(async (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
  }, []);

  useEffect(() => {
    setSelected(assignedGroups.map((item) => item.id));
  }, [isEdit, assignedGroups]);

  const agentCheckToggle = (id: number) => {
    setSelected((prev) => {
      const index = prev.indexOf(id);
      const newList = [...prev];

      if (index === -1) {
        newList.push(id);
      } else {
        newList.splice(index, 1);
      }

      return newList;
    });
  };

  const onRemoveAll = () => {
    setSelected([]);
  };

  const onSave = () => {
    update({ groups: selected });
  };

  const assignedListItem = assignedGroups.map((item) => {
    return (
      <Chip
        sx={{
          margin: '4px',
          backgroundColor: Boolean(item.archived) ? grey[600] : blue[500],
          color: '#ffffff',
        }}
        key={item.id}
        title={item.name}
        variant='filled'
        label={item.name}
      />
    );
  });

  const displayList = list.map((item, index) => (
    <ListItem key={item.id} ref={index === list.length - 1 ? lastDataElement : null} disablePadding>
      <ListItemButton onClick={() => agentCheckToggle(item.id)} dense>
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={Boolean(selected.find((queue) => queue === item.id))}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={item.name} secondary={item.archived ? '(Archived)' : undefined} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <SectionCard title={`Groups (${assignedGroups.length})`} disablePadding={isEdit} onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <Box sx={{ marginBottom: 1 }}>
            <PreferenceLegend items={legend} />
          </Box>

          {assignedGroups.length > 0 && <>{assignedListItem}</>}
          {assignedGroups.length === 0 && (
            <Typography variant='body2' align='left' color='textSecondary'>
              No groups selected.
            </Typography>
          )}
        </>
      )}

      {isEdit && (
        <>
          <Box sx={{ padding: 2 }}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={search}
              onChange={onSearchChange}
            />
          </Box>
          <Divider variant='fullWidth' component='hr' />

          <Box sx={{ paddingTop: 0.5, paddingLeft: 2, paddingRight: 2, paddingBottom: 0.5 }}>
            <Button onClick={onRemoveAll} disabled={selected.length === 0}>
              Uncheck All
            </Button>
          </Box>

          <Divider variant='fullWidth' component='hr' />

          <Box sx={{ height: 400, overflow: 'auto' }}>
            <AsyncLoader isLoading={loading && list.length === 0}>
              {displayList.length > 0 && (
                <>
                  <List sx={{ paddingTop: 0, paddingBottom: 0 }}>{displayList}</List>
                  {loading && displayList.length > 0 && <DotLoader align='center' />}

                  {!loading && !hasMore && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      No more results to display
                    </Typography>
                  )}

                  {error && displayList.length > 0 && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      Failed to load groups
                    </Typography>
                  )}
                </>
              )}

              {displayList.length === 0 && debouncedSearch !== '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No groups found matching your search criteria.
                </Typography>
              )}

              {displayList.length === 0 && selected.length === 0 && debouncedSearch === '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No groups currently exist
                </Typography>
              )}

              {displayList.length === 0 && selected.length > 0 && debouncedSearch === '' && (
                <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
                  No more groups to choose from.
                </Typography>
              )}
            </AsyncLoader>
          </Box>

          <Divider variant='fullWidth' component='hr' />

          <Box sx={{ padding: 2, textAlign: 'right' }}>
            <Button onClick={toggleEdit}>Cancel</Button>

            <LoadingButton
              sx={{ marginLeft: 1 }}
              variant='contained'
              disableElevation
              loading={submitting}
              color='primary'
              onClick={onSave}>
              Update
            </LoadingButton>
          </Box>
        </>
      )}
    </SectionCard>
  );
};

export default DiallerGroupFilterPreferences;
