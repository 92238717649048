import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import SectionCard from '~components/SectionCard';
import useConnectStats from '~hooks/useConnectStats';
import { DiallerGroupPredictiveSettings, UpdateDiallerGroup } from '~pages/CampaignManagement/domain';

interface PredictiveSettingsFormProps {
  predictiveSettings: DiallerGroupPredictiveSettings;
  isConnectPredictive: boolean;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  update: (data: Partial<UpdateDiallerGroup>) => Promise<void>;
}

interface Form {
  ringTimeSeconds: number;
  holdDropSeconds: number;
  maxLinesPerAgent: number;
  lookaheadTimeSeconds: number;
  numReservedAgents: number;
  outboundQueue: string;
}

const PredictiveSettingsForm = ({
  predictiveSettings,
  isConnectPredictive,
  isEdit,
  submitting,
  toggleEdit,
  update,
}: PredictiveSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading, queueStats } = useConnectStats();
  const isLoading = loading || submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      ringTimeSeconds: 0,
      holdDropSeconds: 0,
      maxLinesPerAgent: 0,
      lookaheadTimeSeconds: 0,
      numReservedAgents: 0,
      outboundQueue: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit === true) {
      setValue('ringTimeSeconds', predictiveSettings.ringTimeSeconds);
      setValue('holdDropSeconds', predictiveSettings.holdDropSeconds);
      setValue('maxLinesPerAgent', predictiveSettings.maxLinesPerAgent);
      setValue('lookaheadTimeSeconds', predictiveSettings.lookaheadTimeSeconds);
      setValue('numReservedAgents', predictiveSettings.numReservedAgents);

      if (isConnectPredictive) {
        setValue('outboundQueue', predictiveSettings.outboundQueue || '');
      }
    } else {
      reset();
    }
  }, [isEdit]);

  const onSubmit = handleSubmit(async (data: Form) => {
    const submitData: Partial<UpdateDiallerGroup> = {
      predictiveSettings: {
        ringTimeSeconds: data.ringTimeSeconds,
        holdDropSeconds: data.holdDropSeconds,
        maxLinesPerAgent: data.maxLinesPerAgent,
        lookaheadTimeSeconds: data.lookaheadTimeSeconds,
        numReservedAgents: data.numReservedAgents,
        outboundQueue: data.outboundQueue || undefined,
      },
    };

    update(submitData);
  });

  let outboundQueueName = predictiveSettings.outboundQueue
    ? queueStats[predictiveSettings.outboundQueue]?.name
    : predictiveSettings.outboundQueue;

  const queueIdList = Object.keys(queueStats).sort((a, b) => {
    let an = queueStats[a]?.name;
    let bn = queueStats[b]?.name;
    return an == bn ? 0 : an > bn ? 1 : -1;
  });

  return (
    <SectionCard title='Predictive Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='Ring Time Seconds'
            tooltip='The amount of seconds for ring time. Once this time is exhausted, the dialler will hang up.'
            value={predictiveSettings.ringTimeSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Hold Drop Seconds'
            tooltip='The number of seconds to wait for a subsequent agent to become available (if the initial agent is unavailable) before a call is disconnected.'
            value={predictiveSettings.holdDropSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Max Lines Per Agent'
            tooltip='The number of leads that can be processed simultaneously. This setting defines how aggressive the predictive dialling strategy is. The higher the number, the more aggressive the strategy will be.'
            value={predictiveSettings.maxLinesPerAgent}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Lookahead Time Seconds'
            tooltip='The amount of seconds the dialler can look ahead to predict when an agent will be available and therefore assign and dial the next lead.'
            value={predictiveSettings.lookaheadTimeSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Number Reserved Agents'
            tooltip='The number of agents to reserve for inbound return calls only.'
            value={predictiveSettings.numReservedAgents}
          />
          {isConnectPredictive && (
            <DataItem
              stacked={isExtraSmall}
              title='Outbound Queue'
              tooltip='Used to know how many agents are available for calls and how many calls are in-flight that may take priority.'
              value={outboundQueueName}
            />
          )}
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledNumberField
                name='ringTimeSeconds'
                control={control}
                rules={{
                  required: 'ring time seconds is required.',
                }}
                label='Ring Time Seconds'
                disabled={isLoading}
                required={true}
                error={Boolean(errors.ringTimeSeconds)}
                helperText={errors.ringTimeSeconds?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledNumberField
                name='holdDropSeconds'
                control={control}
                rules={{
                  required: 'hold drop seconds is required.',
                }}
                label='Hold Drop Seconds'
                disabled={isLoading}
                required={true}
                error={Boolean(errors.holdDropSeconds)}
                helperText={errors.holdDropSeconds?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledNumberField
                name='maxLinesPerAgent'
                control={control}
                rules={{
                  required: 'max lines per agent is required.',
                }}
                label='Max Lines Per Agent'
                disabled={isLoading}
                required={true}
                isFloat={true}
                error={Boolean(errors.maxLinesPerAgent)}
                helperText={errors.maxLinesPerAgent?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledNumberField
                name='lookaheadTimeSeconds'
                control={control}
                rules={{
                  required: 'lookahead time seconds is required.',
                }}
                label='Lookahead Time Seconds'
                disabled={isLoading}
                required={true}
                error={Boolean(errors.lookaheadTimeSeconds)}
                helperText={errors.lookaheadTimeSeconds?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledNumberField
                name='numReservedAgents'
                control={control}
                rules={{
                  required: 'number of reserved agents is required.',
                }}
                label='Number of Reserved Agents'
                disabled={isLoading}
                required={true}
                error={Boolean(errors.numReservedAgents)}
                helperText={errors.numReservedAgents?.message}
              />
            </Grid>

            {isConnectPredictive && (
              <Grid item xs={12}>
                <Controller
                  name='outboundQueue'
                  control={control}
                  rules={{
                    required: 'Outbound queue is required',
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      options={queueIdList}
                      disabled={isLoading}
                      disableClearable
                      getOptionLabel={(option) => queueStats[option]?.name || ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          <Typography variant='body1' color='textPrimary' component='p'>
                            {queueStats[option]?.name}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Outbound Queue'
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.outboundQueue)}
                          helperText={errors.outboundQueue?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={isLoading}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PredictiveSettingsForm;
